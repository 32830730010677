import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import { Row, Col } from "react-bootstrap";
import downloadIcon from "../../../scss/styles/images/DownloadIcon.svg";
import Icons from "../../../scss/prh-icon-sprite.svg";
import { exportCSVFile } from "../../../helpers/csvExport";
import UseWindowSize from "../../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../../constants/constants";

const ResultCount = (props) => {
  const { langCode, translate } = useContext(I18nContext);
  const size = UseWindowSize();

  const sorterButton = (
    <button
      type="button"
      data-cy="sorterButton"
      style={
        props.filtersSelected
          ? { display: "block", backgroundColor: "#c2529f", borderColor: "#c2529f" }
          : { display: "block" }
      }
      className={
        props.filtersSelected
          ? "btn btn-primary pr-2 pl-0"
          : "btn btn-secondary pr-2 pl-0"
      }
      onClick={props.toggleShowSortersAndFilters}
    >
      <Row className="pt-1">
        <Col xs={props.filtersSelected ? 12 : 11} className="p-0">
          <span
            style={size.width < bootStrapGrid.sm ? { fontSize: "16px" } : {}}
          >
            {props.filtersSelected
              ? translate("patent_filters_on")
              : translate("patent_filter_and_sort")}
          </span>
        </Col>
        {props.filtersSelected ? (
          ""
        ) : (
          <Col xs={1} className="p-0">
            <svg
              alt=""
              className="prh-icon"
              style={{ height: "21px", width: "21px" }}
            >
              <use xlinkHref={`${Icons}#chevron-right`}></use>
            </svg>
          </Col>
        )}
      </Row>
    </button>
  );

  const handleClick = () => {
    exportCSVFile(props.data.results, "results", langCode);
  };
  return (

    <div className="pl-0" style={{display: "inline-block"}}>
      <h2 className="pr-1" role="status" style={{display: "inline"}}>
        {translate("search_result_count") + " " + props.count}
        {props.count > 3000
          ? " / " + translate("patent_search_show_amount")
          : ""}
          </h2>
        <button
          onClick={handleClick}
          className="btn-icon"
          style={{ border: "none", backgroundColor: "white", display: "inline"}}
        >
          <abbr
            title={translate("patent_download_csv")}
            style={{ cursor: "pointer" }}
          >
            <img
              src={downloadIcon}
              alt=""
              aria-hidden
              id="download"
              className="mb-2"
              height="22"
            />
          </abbr>
        </button>
      
      {sorterButton}
    </div>
  );
};

export default ResultCount;
