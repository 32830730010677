import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../../i18n";
import { Col, Row } from "react-bootstrap";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";

const LanguageSelect = (props) => {
  const { dispatch } = useContext(I18nContext);
  const size = UseWindowSize();

  const onLanguageSelect = (e) => {
    const selectedLanguage = e.target ? e.target.id : e;
    dispatch({ type: "setLanguage", payload: selectedLanguage });
    document.documentElement.lang = selectedLanguage;

    const pathAsArray = props.location.pathname.split( '/' );
    pathAsArray.splice( pathAsArray.indexOf( props.pathParams.getLang() ), 1, selectedLanguage );
    const newPath = pathAsArray.join( '/' );

    props.history.push({
      pathname: newPath,
    });
  };

  const mobileLanguageSelect = (
    <Row
      className="prh-collapse-header pr-4 pt-0 pb-1"
      style={{ flexWrap: "wrap" }}
    >
      {document.documentElement.lang !== "fi" && (
        <Col xs={12}>
          <button className="nav-link">
            <li className="nav-item ">
              <span
                id="fi"
                onClick={onLanguageSelect}
                className="nav-link"
                lang="fi"
              >
                Suomeksi
              </span>
            </li>
          </button>
        </Col>
      )}
      {document.documentElement.lang !== "sv" && (
        <Col xs={12}>
          <button className="nav-link">
            <li className="nav-item pr-4">
              <span
                id="sv"
                onClick={onLanguageSelect}
                lang="sv"
                className="nav-link"
              >
                På svenska
              </span>
            </li>
          </button>
        </Col>
      )}
      {document.documentElement.lang !== "en" && (
        <Col xs={12}>
          <button className="nav-link">
            <li className="nav-item pr-4">
              <span
                id="en"
                onClick={onLanguageSelect}
                lang="en"
                className="nav-link"
              >
                In English
              </span>
            </li>
          </button>
        </Col>
      )}
    </Row>
  );

  const largeLanguageSelect = (
    <div className="align-items-center justify-content-between pb-3 pt-3 ">
      {document.documentElement.lang !== "fi" && (
        <button
          className="mt-3 ml-3"
          id="fi"
          lang="fi"
          onClick={onLanguageSelect}
          style={{ color: "white" }}
        >
          Suomeksi
        </button>
      )}
      {document.documentElement.lang !== "sv" && (
        <button
          className="mt-3 ml-3"
          id="sv"
          lang="sv"
          onClick={onLanguageSelect}
          style={{ color: "white" }}
        >
          På svenska
        </button>
      )}
      {document.documentElement.lang !== "en" && (
        <button
          className="mt-3 ml-3"
          id="en"
          lang="en"
          onClick={onLanguageSelect}
          style={{ color: "white" }}
        >
          In English
        </button>
      )}
    </div>
  );

  return (
    <div>
      {size.width < bootStrapGrid.sm
        ? mobileLanguageSelect
        : largeLanguageSelect}
    </div>
  );
};

export default withRouter(LanguageSelect);
