import React, { useContext, useState, useRef, useEffect } from "react";
import { I18nContext } from "../../../i18n";
import { Row, Col } from "react-bootstrap";
import ToolTip from "../../common/ToolTip";
import Icons from "../../../scss/prh-icon-sprite.svg";
import { findLanguageVersion } from "../../../helpers/helpers";

const ResultSorter = (props) => {
  const { translate } = useContext(I18nContext);
  const [sorter, setSorter] = useState("");
  const [ascending, setAscending] = useState(true);
  const ascendingRef = useRef();
  const buttonRef = useRef();
  const lang = document.documentElement.lang;

  useEffect(() => {
    if (props.sorter) {
      setSorter(props.sorter.sort);
      setAscending(props.sorter.asc);
      ascendingRef.current = props.sorter.asc;
      sortResults(props.sorter.sort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sorter]);

  useEffect(() => {
    if (sorter) {
      buttonRef.current.focus();
    }
  }, [sorter, buttonRef]);
  const handleClick = (event) => {
    if (event.target.value === sorter) {
      ascendingRef.current = !ascending;
      setAscending(!ascending);
      props.onChangeSorter({ sort: event.target.value, asc: !ascending });
    } else {
      ascendingRef.current = true;
      setSorter(event.target.value);
      setAscending(true);
      props.onChangeSorter({ sort: event.target.value, asc: true });
    }
    sortResults(event.target.value);
  };

  const handleChevronClick = () => {
    ascendingRef.current = !ascending;
    setAscending(!ascending);
    sortResults(sorter);
  };

  const listSortWithEmpty = (a, b) => {
    if (a.length === 0 && ascendingRef.current) {
      return 1;
    }
    if (b.length === 0 && ascendingRef.current) {
      return -1;
    }
    if (a.length === 0 && !ascendingRef.current) {
      return -1;
    }
    if (b.length === 0 && !ascendingRef.current) {
      return 1;
    }
  };

  const stringSortWithEmptyValue = (a, b, newSorter) => {
    if (!a[newSorter] && ascendingRef.current) {
      return 1;
    }
    if (!b[newSorter] && ascendingRef.current) {
      return -1;
    }
    if (!a[newSorter] && !ascendingRef.current) {
      return -1;
    }
    if (!b[newSorter] && !ascendingRef.current) {
      return 1;
    }
  };
  const sortResults = (newSorter) => {
    const oldList = [...props.data.results];
    let sortedList = [];
    switch (newSorter) {
      case "title":
        sortedList = oldList.sort((a, b) => {
          if (a.titles.length === 0 || b.titles.length === 0) {
            return listSortWithEmpty(a.titles, b.titles);
          }
          return findLanguageVersion(a.titles, lang).title.localeCompare(
            findLanguageVersion(b.titles, lang).title
          );
        });
        break;
      case "applicant":
        sortedList = oldList.sort((a, b) => {
          if (a.applicants.length === 0 || b.applicants.length === 0) {
            return listSortWithEmpty(a.applicants, b.applicants);
          }
          if (!a.applicants[0].name) {
            a.applicants[0].name = a.applicants[0].companyName;
          }
          if (!b.applicants[0].name) {
            b.applicants[0].name = b.applicants[0].companyName;
          }
          return a.applicants[0].name.localeCompare(b.applicants[0].name);
        });
        break;
      case "dossierStatus":
        sortedList = oldList.sort((a, b) => {
          if (!a[newSorter] || !b[newSorter]) {
            return stringSortWithEmptyValue(a, b, newSorter);
          }
          return translate("patents_status_" + a.dossierStatus).localeCompare(
            translate("patents_status_" + b.dossierStatus)
          );
        });
        break;
      default:
        sortedList = oldList.sort((a, b) => {
          if (!a[newSorter] || !b[newSorter]) {
            return stringSortWithEmptyValue(a, b, newSorter);
          }
          return a[newSorter].localeCompare(b[newSorter]);
        });
    }
    if (!ascendingRef.current) {
      sortedList.reverse();
    }
    const sortedResults = {
      results: sortedList,
      totalResults: props.data.totalResults,
    };
    if (sorter !== null && sorter !== "") {
      props.setShowItems(sortedResults);
    }
  };

  const SortField = (props) => (
    <Row
      className="m-0 pt-1 pb-2"
      style={{ backgroundColor: props.value === sorter ? "#ebebeb" : "white" }}
    >
      <Col xs={10}>
        <button
          onClick={handleClick}
          aria-pressed={props.value === sorter}
          aria-label={
            props.text +
            " " +
            (ascending
              ? translate("patent_sorting_ascending")
              : translate("patent_sorting_descending"))
          }
          value={props.value}
          id={props.value}
          data-cy={props.data_cy}
          className={
            props.value === sorter ? "select-btn-selected" : "select-btn"
          }
          ref={buttonRef}
        >
          {props.text}
        </button>
      </Col>
      {props.value === sorter && (
        <Col xs={2}>
          <button
            onClick={handleChevronClick}
            aria-hidden
            tabIndex="-1"
            className={
              props.value === sorter ? "select-btn-selected" : "select-btn"
            }
          >
            {ascending ? (
              <svg
                alt={translate("patent_sorting_ascending")}
                aria-label={translate("result_filters_switch_order")}
                className="sort-chevron-icon p-0"
              >
                <use xlinkHref={`${Icons}#chevron-down`}></use>
              </svg>
            ) : (
              <svg
                alt={translate("patent_sorting_descending")}
                aria-label={translate("result_filters_switch_order")}
                className="sort-chevron-icon p-0"
              >
                <use xlinkHref={`${Icons}#chevron-up`}></use>
              </svg>
            )}
          </button>
        </Col>
      )}
    </Row>
  );

  return (
    <div
      aria-describedby="ptSorterTooltip"
      style={{ backgroundColor: "white" }}
    >
      <Row className="m-0 p-2">
        <Col
          xs={"auto"}
          className="pr-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h4 className="selection-title m-0" style={{ fontSize: "1.1rem" }}>
            {translate("patent_sorting")}
          </h4>
        </Col>
        <Col xs={"auto"} className="pl-0 pr-0">
          <ToolTip
            text={translate("patent_infotext_sorting")}
            id="ptSorterTooltip"
          />
        </Col>
        <Col></Col>
      </Row>
      <SortField
        text={translate("patent_searchform_applicationnumber")}
        value="applicationNumber"
        id="applicationNumber"
        data_cy="applicationNumberSort"
      />
      <SortField
        text={translate("patent_searchform_registrationnumber")}
        value="registrationNumber"
        data_cy="registrationNumberSort"
        id="registrationNumber"
      />
      <SortField
        text={translate("patent_searchform_patent_title")}
        value="title"
        id="title"
        data_cy="titleSort"
      />
      <SortField
        text={translate("patent_searchform_applicant")}
        value="applicant"
        id="applicant"
        data_cy="applicantSort"
      />
      <SortField
        text={translate("patent:resultfield_status")}
        value="dossierStatus"
        id="dossierStatus"
        data_cy="dossierStatusSort"
      />
      <SortField
        text={translate("patent:resultfield_applicationdate")}
        value="applicationDate"
        id="applicationDate"
        data_cy="applicationDateSort"
      />
    </div>
  );
};

export default ResultSorter;
