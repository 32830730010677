import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import ResultCount from "./ResultCount";
import { I18nContext } from "../../../i18n";
import SortersAndFilters from "../sortersAndFilters/SortersAndFilters";
import UseWindowSize from "../../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../../constants/constants";
import tableViewIcon from "../../../scss/styles/images/TableViewIcon.svg";
import cardViewIcon from "../../../scss/styles/images/CardViewIcon.svg";

const ResultBar = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  const handleToggle = (bool) => {
    props.setGridState(bool);
  };

  return (
    <div>
      <Row className="mt-2 align-items-stretch pb-1">
        <Col
          xs={6}
          className="pr-0"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <ResultCount
            count={props.count}
            data={props.data}
            setGridState={props.setGridState}
            toggleShowSortersAndFilters={props.toggleShowSortersAndFilters}
          />
        </Col>
        <Col
          style={
            size.width >= bootStrapGrid.sm
              ? {
                  display: "flex",
                  alignSelf: "flex-end",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                }
              : {}
          }
        >
          {size.width >= bootStrapGrid.lg
            ? translate("patent_searchresult_display_settings")
            : null}
          {size.width >= bootStrapGrid.lg ? (
            <button
              onClick={() => {
                handleToggle(true);
              }}
              aria-pressed={props.gridState}
              className=""
              data-cy="tableViewIcon"
              style={{ border: "none", backgroundColor: "white" }}
            >
              <abbr
                title={translate("patent_navigation_tableview")}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={tableViewIcon}
                  alt={translate("patent_navigation_tableview")}
                  id=""
                  className=""
                  height="22"
                />
              </abbr>
            </button>
          ) : (
            ""
          )}
          {size.width >= bootStrapGrid.lg ? (
            <button
              onClick={() => {
                handleToggle(false);
              }}
              aria-pressed={!props.gridState}
              data-cy="cardViewIcon"
              className="btn-icon"
              style={{
                border: "none",
                backgroundColor: "white",
              }}
            >
              <abbr
                title={translate("patent_navigation_prhcardview")}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={cardViewIcon}
                  alt={translate("patent_navigation_prhcardview")}
                  id="download"
                  className="btn-icon"
                  height="22"
                />
              </abbr>
            </button>
          ) : (
            ""
          )}
        </Col>
      </Row>
      {props.showSortersAndFilters && size.width < bootStrapGrid.lg ? (
        <SortersAndFilters
          setShowItems={props.setShowItems}
          data={props.data}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
          fetchSearchResults={props.fetchSearchResults}
          setFiltersSelected={props.setFiltersSelected}
          sorter={props.sorter}
          onChangeSorter={props.onChangeSorter}
        />
      ) : null}
    </div>
  );
};

export default ResultBar;
