import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { I18nContext } from "../../i18n";
import PageNotFound from "../errors/PageNotFound";
import { formatDate } from "../../helpers/helpers";
import { bootStrapGrid } from "../../constants/constants";
import DataField from "../common/DataField";
import UseWindowSize from "../../customHooks/useWindowSize";
import Accordion from "../common/Accordion";

const paymentUrl = process.env.REACT_APP_PAYMENT_SERVICE_URL;
const type = {
  R: "recordal",
  V: "opposition",
  M: "opposition",
  UV: "opposition",
  PV: "opposition",
};

const RelatedDossierDetails = (props) => {
  //const { translate, langCode } = useContext(I18nContext);
  const { translate, dispatch } = useContext(I18nContext);
  const size = UseWindowSize();
  const data = props.recordal.data;
  const patent = props.patent.data;
  const lang = document.documentElement.lang;

  const handlePrint = () => {
    window.print();
  };

  const fetchRecordal = props.fetchRecordal;
  let path = `patent/${
    type[
      props.match.params.applicationNumber[1] === "V"
        ? props.match.params.applicationNumber[0] +
          props.match.params.applicationNumber[1]
        : props.match.params.applicationNumber[0]
    ]
  }/${props.match.params.applicationNumber}`;

  const fetchPatent = props.fetchPatent;

  useEffect(() => {
    fetchRecordal(path);
    if (
      props.match.params.lang !== "fi" &&
      document.documentElement.lang !== props.match.params.lang
    ) {
      document.documentElement.lang = props.match.params.lang;
      dispatch({ type: "setLanguage", payload: props.match.params.lang });
    }
  }, []);

  useEffect(() => {
    const appNum =
      props?.recordal?.data?.relatedDossiers?.length > 0
        ? props.recordal.data.relatedDossiers[0].applicationNumber
        : null;
    if (appNum) {
      fetchPatent("patent/" + appNum);
    }
  }, [props.recordal.data]);

  if (data.length < 1) {
    if (props.recordal.error)
      return (
        <PageNotFound
          title="patent_not_found"
          description="patent_not_found_description"
        />
      );

    return null;
  }

  const oppositionNumber = (
    <dl className="pb-2">
      <dt className="m-0 p-0">
        {translate("opposition:details_applicationnumber")}
      </dt>
      <dd className="datafield-content" style={{ fontSize: "1.2rem" }}>
        {data.applicationNumber}
      </dd>
    </dl>
  );

  const applicationDate = (
    <DataField
      title={translate("patent:resultfield_applicationdate")}
      value={formatDate(data.applicationDate) || "-"}
    />
  );

  const applicant = (
    <div>
      {data.applicants && data.applicants.length > 0 ? (
        <div className="pb-2">
          <dt className="m-0 p-0">
            {translate("opposition:details_applicant")}
          </dt>
          {data.applicants.map((element) =>
            element.companyName ? (
              <dd className="datafield-content pb-0 mb-0" key={element.ordinal}>
                {element.companyName}
              </dd>
            ) : (
              <dd className="datafield-content pb-0 mb-0" key={element.ordinal}>
                {(element.lastName ? element.lastName : "") +
                  " " +
                  (element.firstName ? element.firstName : "")}
              </dd>
            )
          )}
        </div>
      ) : (
        <div className="pb-2">
          <dt className="m-0 p-0">
            {translate("opposition:details_applicant")}
          </dt>
          <dd className="datafield-content pb-0 mb-0">-</dd>
        </div>
      )}
    </div>
  );

  const owner = (
    <div>
      {data.owners && data.owners.length > 0 ? (
        <div className="pb-2">
          <dt className="m-0 p-0">{translate("opposition:details_owner")}</dt>
          {data.owners.map((element) =>
            element.companyName ? (
              <dd className="datafield-content pb-0 mb-0" key={element.ordinal}>
                {element.companyName}
              </dd>
            ) : (
              <dd className="datafield-content pb-0 mb-0" key={element.ordinal}>
                {(element.lastName ? element.lastName : "") +
                  " " +
                  (element.firstName ? element.firstName : "")}
              </dd>
            )
          )}
        </div>
      ) : (
        <div className="pb-2">
          <dt className="m-0 p-0">{translate("opposition:details_owner")}</dt>
          <dd className="datafield-content pb-0 mb-0">-</dd>
        </div>
      )}
    </div>
  );

  const registrationNumber = (
    <div className="pb-2">
      <dt className="m-0 p-0">
        {translate("patent:resultfield_registrationNumber")}
      </dt>
      <dd className="datafield-content pb-0 mb-0">
        {patent.applicationNumber}
      </dd>
      <dd className="datafield-content pb-0 mb-0">
        {patent.registrationNumber}
      </dd>
    </div>
  );

  const sortedDocuments =
    data.documents.length < 1
      ? data.documents
      : data.documents.document.length < 2
      ? data.documents.document
      : data.documents.document
          .sort((a, b) => {
            if (a?.documentDate)
              return a?.documentDate.localeCompare(b?.documentDate);
            return 0;
          })
          .reverse();

  const documentAccordionContent = (
    <div className="p-4">
      <table className="table">
        <thead>
          <tr>
            <th>
              <span>{translate("patent:details_document")}</span>
            </th>
            <th>
              <span>{translate("patent:details_date")}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {sortedDocuments.map((doc) => (
            <tr key={doc.ordinal}>
              <td>
                <a href={doc.url}>
                  {translate("patent_document_kind_" + doc.documentSubKind) +
                    " (" +
                    doc.languageCode +
                    ").pdf"}
                </a>
              </td>
              <td>{formatDate(doc.documentDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const largeView = (
    <div>
      <Row>
        <Col xs={12} md={4}>
          {registrationNumber}
        </Col>
        <Col xs={12} md={4}>
          {owner}
        </Col>
        <Col xs={12} md={4}>
          {applicant}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          {applicationDate}
        </Col>
        <Col xs={12} md={4}></Col>
        <Col xs={12} md={4}></Col>
      </Row>
    </div>
  );

  const mobileView = (
    <div>
      <Row>
        <Col xs={12}>{registrationNumber}</Col>
        <Col xs={12}>{owner}</Col>
        <Col xs={12}>{applicant}</Col>
        <Col xs={12}>{applicationDate}</Col>
      </Row>
    </div>
  );

  return (
    <Container>
      <Row className="pt-4 pb-4">
        <Col xs={7} className="pt-2">
          <h2>
            {translate(
              "patent_dossier_related_dossier_type_" +
                data.relatedDossierType.toLowerCase()
            )}
          </h2>
        </Col>
        <Col xs={5} className="d-flex justify-content-end d-print-none">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={handlePrint}
          >
            <span className="ml-1 mr-1">
              {translate("patent:details_print_button")}
            </span>
          </button>
        </Col>
      </Row>
      <Row style={{ borderBottom: "1px solid #b1b1b1" }}>
        <Col>{oppositionNumber}</Col>
      </Row>
      <Row className="pt-4 pb-4" style={{ borderBottom: "1px solid #b1b1b1" }}>
        <Col>{size.width < bootStrapGrid.sm ? mobileView : largeView}</Col>
      </Row>
      <Row className="pt-4 pb-4" style={{ borderBottom: "1px solid #b1b1b1" }}>
        <Col>
          <Accordion
            title={translate("patent:details_application_documents")}
            content={documentAccordionContent}
            id="applicationDocuments"
          ></Accordion>
        </Col>
      </Row>
      {data.relatedDossiers && (
        <div class="mt-4 mb-4">
          <div class="col-8 row">
            <h2>{translate("opposition:details_relateddossier")}</h2>
          </div>
          <div class="p-2 d-flex flex-row flex-wrap">
            {data.relatedDossiers.map((e) => (
              <Link
                aria-label={
                  translate("patent_detailed_link") + e.applicationNumber
                }
                aria-described
                className="btn-link p-0 datafield-content"
                style={{
                  color: "#035ca7",
                  textDecoration: "underline",
                }}
                id="applicationNumber"
                to={{
                  pathname: props.pathParams.buildPath(
                    "patent/" + e.applicationNumber
                  ),
                }}
              >
                <dd
                  className="datafield-content pb-0 mb-0"
                  style={{
                    color: "#035ca7",
                    textDecoration: "underline",
                  }}
                  id="applicationNumber"
                  data-cy="applicationNumber"
                >
                  {e.applicationNumber}
                </dd>
              </Link>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default withRouter(RelatedDossierDetails);
