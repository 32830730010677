import React, { useState, useEffect, useContext } from "react";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrhTableView from "./PrhTableView";
import PrhCardView from "./PrhCardView";
import ResultBar from "./ResultBar/ResultBar";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";
import SortersAndFilters from "./sortersAndFilters/SortersAndFilters";
import { I18nContext } from "../../i18n";

const SearchResults = (props) => {
  const { translate } = useContext(I18nContext);

  const [showItems, setShowItems] = useState([]);
  const [gridState, setGridState] = useState(true);

  const size = UseWindowSize();

  useEffect(() => {
    setShowItems(props.data);
  }, [props.data]);

  const changeView = (newView) => {
    props.setViewMode(newView);
  };

  if (props.loading) {
    return (
      <div className="p-4" style={{ textAlign: "center" }}>
        <Spinner
          aria-live="assertive"
          aria-label={translate("spinner_loading")}
          className="spinner-border"
        />
      </div>
    );
  }

  if (props.data.length === 0) {
    return null;
  }

  if (showItems.length === 0) {
    return null;
  }

  return (
    <Container>
      <ResultBar
        count={props.data.results.length}
        toggleShowSortersAndFilters={props.toggleShowSortersAndFilters}
        showSortersAndFilters={props.showSortersAndFilters}
        searchParams={props.searchParams}
        setShowItems={props.setShowItems}
        fetchSearchResults={props.fetchSearchResults}
        data={props.data}
        setSearchParams={props.setSearchParams}
        filtersSelected={props.filtersSelected}
        setFiltersSelected={props.setFiltersSelected}
        setGridState={setGridState}
        gridState={gridState}
        sorter={props.sorter}
        onChangeSorter={props.onChangeSorter}
      />
      <Row>
        {props.showSortersAndFilters && size.width >= bootStrapGrid.lg ? (
          <Col lg={0} xl={5} className=" pr-0">
            <SortersAndFilters
              setShowItems={props.setShowItems}
              data={props.data}
              searchParams={props.searchParams}
              setSearchParams={props.setSearchParams}
              fetchSearchResults={props.fetchSearchResults}
              setFiltersSelected={props.setFiltersSelected}
              sorter={props.sorter}
              onChangeSorter={props.onChangeSorter}
            />
          </Col>
        ) : null}

        <Col
          lg={12}
          xl={
            props.showSortersAndFilters && size.width >= bootStrapGrid.lg
              ? 7
              : 12
          }
        >
          {size.width >= bootStrapGrid.lg && gridState ? (
            <PrhTableView
              changeView={changeView}
              data={showItems}
              onChangePage={props.onChangePage}
              currentPage={props.currentPage}
              showSortersAndFilters={props.showSortersAndFilters}
              pathParams={props.pathParams}
            />
          ) : (
            ""
          )}
          {size.width < bootStrapGrid.lg || !gridState ? (
            <PrhCardView
              changeView={changeView}
              data={showItems}
              onChangePage={props.onChangePage}
              currentPage={props.currentPage}
              pathParams={props.pathParams}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SearchResults;
