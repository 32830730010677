import React, { useContext, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { I18nContext } from "../../i18n";
import { withRouter } from "react-router-dom";
import { initialSearchParams, bootStrapGrid } from "../../constants/constants";
import ToolTip from "../common/ToolTip";
import UseWindowSize from "../../customHooks/useWindowSize";

const BasicSearch = (props) => {
  const { translate } = useContext(I18nContext);
  const [validationError, setValidationError] = useState(false);
  const size = UseWindowSize();
  const handleSubmit = () => {
    const newParams = {
      ...initialSearchParams,
      basicSearch: props.searchParams.basicSearch,
    };
    if (newParams.basicSearch.length < 1000) {
      props.fetchSearchResults(newParams);
      props.history.push(props.pathParams.buildPath("results"));
    } else {
      setValidationError(true);
    }
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const onChangeHandler = (event) => {
    let newParams = { ...props.searchParams };
    newParams.basicSearch = event.currentTarget.value;
    props.setSearchParams(newParams);
  };

  if (!props.searchParams) {
    return null;
  }

  const searchField = (
    <div className="searchfield-container p-4">
      <div className="pt-3 pb-3">
        <div className="input-group">
          <input
            aria-label={translate("patent.application.search")}
            aria-describedby="searchInstructions"
            autoComplete="off"
            type="text"
            className={
              !validationError
                ? "form-control align-self-center border border-dark"
                : "form-control align-self-center is-invalid"
            }
            onKeyUp={(event) => keyPressHandler(event)}
            onChange={onChangeHandler}
            role="search"
            id="search_word"
            data-cy="search_word"
            value={props.searchParams.basicSearch}
            placeholder={translate("patent.application.search.word")}
          />

          <div className="input-group-append">
            <button
              data-cy="search-btn"
              className="btn btn-primary"
              onClick={() => handleSubmit()}
            >
              {translate("patent.application.search.button")}
            </button>
          </div>
        </div>
        <Row>
          <Col className="pt-3">
            <Link data-cy="search" to={props.pathParams.buildPath("search")}>
              <span
                className=""
                style={{ fontSize: "1.1rem", textDecoration: "underline" }}
              >
                {translate("patent.application.advanced.search")}
              </span>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <div>
      <div>
        <Row className="searchfield-background  mr-0">
          <Col className="pr-0">
            <Container>
              <Row className="align-items-center mt-2 pt-4 pb-2">
                <Col xs="auto" className="pr-0 m-0">
                  <h1 className="m-0">
                    {translate("patent.application.search")}
                  </h1>
                </Col>
                <Col xs="auto" className="pl-0 pt-1">
                  <ToolTip
                    circleStyle="blue"
                    text={translate("patent_infotext_basicsearch")}
                    id="searchInstructions"
                    placement={
                      size.width < bootStrapGrid.sm ? "bottom" : "right"
                    }
                  />
                </Col>
              </Row>
              <div className="pt-4">{searchField}</div>
            </Container>
          </Col>
        </Row>
        <div
          style={
            size.width < bootStrapGrid.sm
              ? { height: "8rem" }
              : size.width < bootStrapGrid.md
              ? { height: "9rem" }
              : { height: "10rem" }
          }
        ></div>
      </div>
    </div>
  );
};

export default withRouter(BasicSearch);
