import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import { I18nContext } from "../../i18n";
import "react-datepicker/dist/react-datepicker.css";

const DatePick = (props) => {
  const [startDate, setStartDate] = useState(props.value);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setStartDate(props.value);
  }, [props.value]);

  return (
    <form onSubmit={useEffect}>
      <div className="alfame-datepicker-wrapper">
        <DatePicker
          id={props.id}
          dateFormat={["dd.MM.yyyy", "ddMMyyyy"]}
          selected={startDate}
          onChange={(date) => props.handleChange(props.id, date)}
          onCalendarClose={() => props.handleFocus()}
          className={"form-control " + (props.valid ? "border border-dark" : "is-invalid")}
          placeholderText={translate("patent_date")}
          name={props.data_cy}
        />
        {props.valid ? null : (
          <div
            className="text-danger"
            style={{fontSize:"80%"}}
            role="alert"
            data-cy={ props.name + "Error"}
          >
            {translate("patent_date_warning")}
          </div>
          )}
      </div>
    </form>
  );
};

export default DatePick;
