import { useState, useEffect } from "react";
import axios from "axios";

export default function useEndpoint(fn) {
  const [res, setRes] = useState({
    data: [],
    loading: false,
    error: false,
  });
  const [req, setReq] = useState();

  useEffect(() => {
    if (!req) return;

    setRes({
      data: [],
      loading: true,
      error: false,
    });
    axios(req)
      .then((res) =>
        setRes({
          data: res.data,
          loading: false,
          error: false,
        })
      )
      .catch((err) => {
        if (err.request.status === "404") {
          setRes({
            data: [],
            loading: false,
            error: [
              {
                errorTitle: "not_found_title",
                errorContent: "not_found_content",
              },
            ],
          });
        } else
          setRes({
            data: [],
            loading: false,
            error: [
              {
                errorTitle: "connection_error_title",
                errorContent: "connection_error_content",
              },
            ],
          });
      });
  }, [req]);

  return [res, (...args) => setReq(fn(...args))];
}
