import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import LanguageSelect from "./LanguageSelect";
import { Link } from "react-router-dom";

const NavMenu = (props ) => {
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <Link to={ props.pathParams.buildPath() }>
        <Row className="pt-2 pb-2">
          <Col className="ml-3">
            <span className="menu-text ml-0" data-cy="home">
              {translate("patent.application.search")}
            </span>
          </Col>
        </Row>
      </Link>
      <Link to={ props.pathParams.buildPath( 'search' )}>
        <Row className="pt-2 pb-2">
          <Col className="">
            <span className="menu-text" data-cy="search">
              {translate("patent.application.advanced.search")}
            </span>
          </Col>
        </Row>
      </Link>
      <Navbar className="navbar navbar-dark navbar-expand-md">
        <Nav>
          <LanguageSelect pathParams = { props.pathParams }/>
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavMenu;
