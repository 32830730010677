import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Pagination from "../common/Pagination";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";
import { I18nContext } from "../../i18n";
import PrhTableCard from "./PrhTableCard";

const PrhTableView = (props) => {
  const size = UseWindowSize();
  const [pageOfItems, setPageOfItems] = useState([]);
  const { translate } = useContext(I18nContext);

  const onChangePage = (currentPage, pageOfItems) => {
    setPageOfItems(pageOfItems);
    props.onChangePage(currentPage);
  };

  let pagesOnBar = 10;
  if (size.width < bootStrapGrid.md) {
    pagesOnBar = 5;
  }

  return (
    <div>
      <table
        className="table table-hover table-stacked-md"
        data-toggle="table"
        data-cy="table"
        id=""
        role="table"
      >
        <thead>
          <tr>
            <th style={{}} id="patentNumber">
              {translate("patent:resultfield_registrationNumber")}
            </th>
            <th style={{}} id="patentName">
              {translate("patent:resultfield_name")}
            </th>
            {!props.showSortersAndFilters && (
              <th
                style={{ textAlign: "center", width: "25%" }}
                id="patentClasses"
              >
                {translate("patent:resultfield_ipc_classes")}
              </th>
            )}
            <th style={{ textAlign: "center" }} id="patentApplicant">
              {translate("patent:resultfield_applicant")}
            </th>
            <th id="patentResult" style={{ textAlign: "center" }}>
              {translate("patent:resultfield_status")}
            </th>
          </tr>
        </thead>
        <tbody>
          {pageOfItems.map((item) => (
            <PrhTableCard
              data={item}
              key={item.applicationNumber}
              showSortersAndFilters={props.showSortersAndFilters}
              pathParams = { props.pathParams }
            />
          ))}
        </tbody>
      </table>
      {/* {pageOfItems.map((item) => (
        <PrhCard data={item} key={item.applicationNumber} />
      ))} */}
      <Row>
        <Col style={{ paddingRight: "0px" }}>
          <Pagination
            items={props.data.results}
            onChangePage={onChangePage}
            pagesOnBar={pagesOnBar}
            currentPage={props.currentPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PrhTableView;
