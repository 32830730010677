import React, { useContext, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { I18nContext } from "../i18n";
import Container from "react-bootstrap/Container";
import MobileNavbar from "../components/header/MobileNavbar";
import ExpandedNavbar from "../components/header/ExpandedNavbar";
import "../scss/styles/app.scss";
import SearchForm from "../components/SearchForm/SearchForm";
import UseWindowSize from "../customHooks/useWindowSize";
import { bootStrapGrid } from "../constants/constants";
import Footer from "../components/common/Footer";
import RelatedDossierDetails from "../components/relatedDossierDetails/RelatedDossierDetails";

const type = {
  R: "recordal",
  V: "opposition",
  M: "opposition",
  PV: "opposition",
};
const RecordalPage = (props) => {
  const { translate, dispatch } = useContext(I18nContext);
  const size = UseWindowSize();
  document.title = translate("search_patent") + " | PRH";

  /* const fetchPatent = props.fetchPatent;
  let path = `patent/${type[props.match.params.applicationNumber[0]]}/${
    props.match.params.applicationNumber
  }`;
 const data = props.patent.data;
  console.log(data);

  useEffect(() => {
    fetchPatent(path);
    if (
      props.match.params.lang !== "fi" &&
      document.documentElement.lang !== props.match.params.lang
    ) {
      document.documentElement.lang = props.match.params.lang;
      dispatch({ type: "setLanguage", payload: props.match.params.lang });
    }
  }, []);*/

  useEffect(() => {
    props.pathParams.updateLanguage();
  }, [props.pathParams]);

  return (
    <>
      <div id="skip">
        <a href="#maincontent">{translate("jumplink_to_content")}</a>
      </div>
      <Container
        fluid="true"
        className=" page-wrapper m-0 p-0 "
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          {size.width < bootStrapGrid.md ? (
            <MobileNavbar pathParams={props.pathParams} />
          ) : (
            <ExpandedNavbar
              activeLink={props.activeLink}
              pathParams={props.pathParams}
            />
          )}
          <main id="maincontent">
            <RelatedDossierDetails
              fetchRecordal={props.fetchRecordal}
              recordal={props.recordal}
              pathParams={props.pathParams}
              patent={props.patent}
              fetchPatent={props.fetchPatent}
            />
            {/*<div className="container">
              <div class="mt-4 mb-4 row">
                <div class="col-8">
                  <h2>{data.relatedDossierType}</h2>
                </div>
                <div class="d-flex justify-content-end d-print-none col-4">
                  <button type="button" class="btn btn-secondary btn-sm">
                    <span>{translate("trademark.portlet.print")}</span>
                  </button>
                </div>
              </div>
              <div class="row col-8">
                <h3>Tiedot</h3>
              </div>
              <div class="d-flex flex-row flex-wrap">
                <div class="p-2">
                  <dl class="">
                    <dt class="m-0 p-0">Hakemusnumero</dt>
                      <dd
                        className="datafield-content pb-0 mb-0"
                        style={{
                          color: "#035ca7",
                          textDecoration: "underline",
                        }}
                        id="applicationNumber"
                        data-cy="applicationNumber"
                      >
                        {data.applicationNumber}
                      </dd>
                  </dl>
                </div>
                <div class="p-2">
                  {data.representatives?.length > 0 && (
                    <dl class="">
                      <dt class="m-0 p-0">Representatives</dt>
                      {data.representatives.map((e) => (
                        <dd class="datafield-content">{e.companyName}</dd>
                      ))}
                    </dl>
                  )}
                </div>
                <div class="p-2">
                  {(data.applicants?.length > 0 || data.owners?.length > 0) && (
                    <dl class="">
                      <dt class="m-0 p-0">Hakija / Haltia</dt>
                      {data.applicants &&
                        data.applicants.map((e) => (
                          <dd class="datafield-content">{e.fullName}</dd>
                        ))}
                      {data.owners &&
                        data.owners.map((e) => (
                          <dd class="datafield-content">{e.companyName}</dd>
                        ))}
                    </dl>
                  )}
                </div>
                <div class="p-2">
                  <dl class="">
                    <dt class="m-0 p-0">Päivämäärä</dt>
                    <dd class="datafield-content">{data.applicationDate}</dd>
                  </dl>
                </div>
              </div>
              {data.relatedDossiers && (
                <div class="mt-4 mb-4">
                  <div class="col-8 row">
                    <h2>RelatedDossiers</h2>
                  </div>
                  <div class="p-2 d-flex flex-row flex-wrap">
                    {data.relatedDossiers.map((e) => (
                      <Link
                        aria-label={
                          translate("patent_detailed_link") +
                          e.applicationNumber
                        }
                        aria-described
                        className="btn-link p-0 datafield-content"
                        style={{
                          color: "#035ca7",
                          textDecoration: "underline",
                        }}
                        id="applicationNumber"
                        to={{
                          pathname: props.pathParams.buildPath( "patent/" + e.applicationNumber )
                        }}
                      >
                        <dd
                          className="datafield-content pb-0 mb-0"
                          style={{
                            color: "#035ca7",
                            textDecoration: "underline",
                          }}
                          id="applicationNumber"
                          data-cy="applicationNumber"
                        >
                          {e.applicationNumber}
                        </dd>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
                        </div>*/}
          </main>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default withRouter(RecordalPage);
