import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Link } from "react-router-dom";
import PrhBrand from "./PrhBrand";
import LanguageSelect from "./LanguageSelect";

const ExpandedNavbar = (props) => {
  const { translate } = useContext(I18nContext);

  return (
    <header className="prh-site-header">
      <div className="container prh-site-header__container">
        <div
          className="navbar navbar-dark navbar-expand-md"
          // aria-label={translate("navbar_extra_options")}
        >
          <PrhBrand pathParams = { props.pathParams }/>
          <div className="navbar-collapse collapse" id="secondary-navigation">
            <ul className="navbar-nav prh-site-header__main-navigation">
              <LanguageSelect pathParams = { props.pathParams }/>
            </ul>
          </div>
        </div>
      </div>
      <div className="container prh-site-header__container prh-site-header__separator">
        <nav
          className="navbar navbar-dark navbar-expand-md"
          aria-label={translate("navbar_navigation")}
        >
          <div className="navbar-collapse collapse" id="primary-navigation">
            <ul className="navbar-nav navbar-collapse__primary_menu">
              <li className="nav-item pl-4" data-cy="navbar-link-searchpage">
                <Link
                  className={
                    props.activeLink === "Home"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to={ props.pathParams.buildPath() }
                  data-cy="home"
                  aria-current={
                    props.activeLink === "Home" ? "page" : "false"
                  }
                  autoFocus={ props.activeLink === "Home" ? true : false }
                >
                  {translate("patent.application.search")}
                  
                </Link>
              </li>
              <li
                className="nav-item pl-4"
                data-cy="navbar-link-paymenthistory"
              >
                <Link
                  className={
                    props.activeLink === "Search"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  data-cy="search"
                  to={ props.pathParams.buildPath( 'search' )}
                  aria-current={
                    props.activeLink === "Search" ? "page" : "false"
                  }
                  autoFocus={ props.activeLink === "Search" ? true : false }
                >
                  {translate("patent.application.advanced.search")}
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default ExpandedNavbar;
