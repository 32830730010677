import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Pagination from "../common/Pagination";
import PrhCard from "./PrhCard";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";

const PrhCardView = (props) => {
  const size = UseWindowSize();
  const [pageOfItems, setPageOfItems] = useState([]);

  const onChangePage = (currentPage, pageOfItems) => {
    setPageOfItems(pageOfItems);
    props.onChangePage(currentPage);
  };

  let pagesOnBar = 10;
  if (size.width < bootStrapGrid.md) {
    pagesOnBar = 5;
  }

  return (
    <div>
      {pageOfItems.map((item) => (
        <PrhCard data={item} key={item.applicationNumber} pathParams = { props.pathParams }/>
      ))}
      <Row>
        <Col style={{ paddingRight: "0px" }}>
          <Pagination
            items={props.data.results}
            onChangePage={onChangePage}
            pagesOnBar={pagesOnBar}
            currentPage={props.currentPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PrhCardView;
