import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import { Link, withRouter } from "react-router-dom";
import Accordion from "../../common/Accordion";
import { formatDate, findLanguageVersion } from "../../../helpers/helpers";

const DetailInfo = (props) => {
  const { translate } = useContext(I18nContext);
  const lang = props.match.params.lang;

  const fields = props.data;
  const abstract =
    fields.abstracts.length > 0
      ? findLanguageVersion(fields.abstracts, lang).content
      : "-";

  const sortedActions =
    fields.events.length > 1
      ? fields.events
          .sort((a, b) => {
            if (a.eventDate === null || b.eventDate === null) return -1;
            return a.eventDate.localeCompare(b.eventDate);
          })
          .reverse()
      : fields.events;

  const actionAccordionContent = (
    <div className="p-4">
      <table className="table">
        <thead>
          <tr>
            <th>
              <span>{translate("patent:details_action")}</span>
            </th>
            <th>
              <span>{translate("patent:details_date")}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedActions.map((element) => (
            <tr key={element.ordinal}>
              <td>
                {element.event
                  ? translate("patent_action_" + element.event.toLowerCase())
                  : "-"}
              </td>
              <td>{formatDate(element.eventDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Row className="pb-2">
        <Col xs={6}></Col>
        <Col xs={6}></Col>
      </Row>
    </div>
  );

  const isOppositionOrPatent = (applicationNumber) => {
    return /^(UV|PV|M|V){0,1}\d{8,10}$/i.test(applicationNumber);
  };

  const isPatent = (applicationNumber) => {
    return /^(EP){0,1}\d{8,10}$/i.test(applicationNumber);
  };

  const dossierAccordionContent = (
    <div className="p-4">
      <table className="table">
        <thead>
          <tr>
            <th>
              <span>{translate("patent:details_dossier")}</span>
            </th>
            <th>
              <span>{translate("patent:details_dossiertype")}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.relatedDossiers.map((element) => (
            <tr key={element.ordinal}>
              <td>
                {translate("patent_dossier_" + element.type.toLowerCase())}
              </td>
              <td>
                {isOppositionOrPatent(element.applicationNumber) ? (
                  <Link
                    aria-label={
                      translate("patent_detailed_link") +
                      element.applicationNumber
                    }
                    aria-described
                    className="btn-link p-0"
                    to={
                      isPatent(element.applicationNumber)
                        ? {
                            pathname: props.pathParams.buildPath(
                              "patent/" + element.applicationNumber
                            ),
                          }
                        : {
                            pathname: props.pathParams.buildPath(
                              "related/" + element.applicationNumber
                            ),
                          }
                    }
                  >
                    <span
                      className="datafield-content pb-0 mb-0"
                      style={{
                        color: "#035ca7",
                        textDecoration: "underline",
                      }}
                      id="applicationNumber"
                      data-cy="applicationNumber"
                    >
                      {element.applicationNumber}
                    </span>
                  </Link>
                ) : (
                  <span
                    className="datafield-content pb-0 mb-0"
                    id="applicationNumber"
                    data-cy="applicationNumber"
                  >
                    {element.applicationNumber}
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const sortedDocuments =
    fields.documents.length < 1
      ? fields.documents
      : fields.documents.document.length < 2
      ? fields.documents.document
      : fields.documents.document
          .sort((a, b) => {
            if (a?.documentDate)
              return a?.documentDate.localeCompare(b?.documentDate);
            return 0;
          })
          .reverse();

  const documentAccordionContent = (
    <div className="p-4">
      <table className="table">
        <thead>
          <tr>
            <th>
              <span>{translate("patent:details_document")}</span>
            </th>
            <th>
              <span>{translate("patent:details_date")}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {sortedDocuments.map((doc) => (
            <tr key={doc.ordinal}>
              <td>
                <a href={doc.url}>
                  {translate("patent_document_kind_" + doc.documentSubKind) +
                    " (" +
                    doc.languageCode +
                    ").pdf"}
                </a>
              </td>
              <td>{formatDate(doc.documentDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      {fields.applicationType !== "SPC" && (
        <dl>
          <Row>
            <Col xs={12} className="pb-2">
              <dt>
                <span>{translate("patent:details_ipc_classes")}</span>
              </dt>
            </Col>

            {fields.ipcClassifications &&
              fields.ipcClassifications.map((element, index) => (
                <Col xs={6} md={4} lg={3} key={index}>
                  <Row>
                    <Col className="pr-0 pr-0" xs={12}>
                      <dd>
                        <span style={{ color: "#323232" }}>{element}</span>
                      </dd>
                    </Col>
                  </Row>
                </Col>
              ))}
          </Row>
          <Row>
            <Col xs={12} className="pb-2 pt-4">
              <dt>
                <span>{translate("patent:details_cpc_classes")}</span>
              </dt>
            </Col>
            {fields.cpcClassifications &&
              fields.cpcClassifications.map((element, index) => (
                <Col xs={6} md={4} lg={3} key={index}>
                  <Row>
                    <Col className="pr-0 pr-0" xs={12}>
                      <dd>
                        <span style={{ color: "#323232" }}>{element}</span>
                      </dd>
                    </Col>
                  </Row>
                </Col>
              ))}
          </Row>
          <Row className="pt-4">
            <Col xs={12}>
              <dt>
                <span>{translate("patent:details_public_picture")}</span>
              </dt>
              <dd>
                {fields.imageUrl ? (
                  <img
                    data-cy="image"
                    className="img-fluid"
                    src={fields.imageUrl}
                    alt={translate("patent_image")}
                  />
                ) : (
                  <div className="pt-2">
                    <span style={{ color: "#323232" }} data-cy="no_image_text">
                      {translate("patent_no_image")}
                    </span>
                  </div>
                )}
              </dd>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={12}>
              <dt>
                <span>
                  {fields.applicationType === "UTILITY_MODEL"
                    ? translate("patent:um_details_summary")
                    : translate("patent:details_summary")}
                </span>
              </dt>
            </Col>

            <Col xs={12} className="pt-2">
              <dd>
                <span style={{ color: "#323232" }}>{abstract}</span>
              </dd>
            </Col>
          </Row>
        </dl>
      )}

      <div className="pt-4">
        <Accordion
          title={translate("patent:details_application_action_info")}
          content={actionAccordionContent}
          id="actionInfo"
        ></Accordion>
      </div>
      <div className="pt-4">
        <Accordion
          title={translate("patent:details_application_documents")}
          content={documentAccordionContent}
          id="applicationDocuments"
        ></Accordion>

        <div className="pt-4">
          <Accordion
            title={translate("patent:details_application_dossiers")}
            content={dossierAccordionContent}
            id="applicationDossiers"
          ></Accordion>
        </div>
      </div>
    </div>
  );
};

export default withRouter(DetailInfo);
