import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MobileNavbar from "../components/header/MobileNavbar";
import ExpandedNavbar from "../components/header/ExpandedNavbar";
import SearchResults from "../components/SearchResults/SearchResults";
import BasicSearch from "../components/SearchForm/BasicSearch";
import "../scss/styles/app.scss";
import UseWindowSize from "../customHooks/useWindowSize";
import { bootStrapGrid } from "../constants/constants";
import { I18nContext } from "../i18n";
import WarningMessage from "../components/errors/WarningMessage";
import Footer from "../components/common/Footer";
import { allPatentTypes, allPatentStatuses } from "../constants/constants";

const SearchResultPage = (props) => {
  const [errors, setErrors] = useState([]);
  const [showSortersAndFilters, setShowSortersAndFilters] = useState(false);
  const [filtersSelected, setFiltersSelected] = useState(false);
  const [data, setData] = useState({});
  const size = UseWindowSize();
  const { translate } = useContext(I18nContext);
  document.title = translate("application_title_searchresults") + " | PRH";

  useEffect(() => {
    setErrors(props.searchResult.error);
  }, [props.searchResult.error]);

  useEffect(() => {
    const filterSelectionActive = () => {
      let allSelected = true;
      allPatentTypes.forEach((item) => {
        if (!props.searchParams.patentTypes.includes(item)) {
          allSelected = false;
        }
      });
      allPatentStatuses.forEach((item) => {
        if (!props.searchParams.dossierStatus.includes(item)) {
          allSelected = false;
        }
      });
      setFiltersSelected(!allSelected);
    };
    filterSelectionActive();
  }, [props.searchParams]);
  useEffect(() => {
    setData(props.searchResult.data);
  }, [props.searchResult]);
  useEffect(() => {
    if (props.showItems?.results?.length > 0) setData(props.showItems);
  }, [props.showItems]);
  useEffect(() => {
    props.pathParams.updateLanguage();
  }, [props.pathParams]);

  const clearError = (index) => {
    if (errors.length > 1) {
      let newErrors = errors.filter((val, i) => i !== index);
      setErrors(newErrors);
    } else {
      setErrors([]);
    }
  };

  const toggleShowSortersAndFilters = () => {
    setShowSortersAndFilters(!showSortersAndFilters);
  };

  return (
    <>
      <div id="skip">
        <a href="#maincontent">{translate("jumplink_to_content")}</a>
      </div>
      <Container
        fluid="true"
        className=" page-wrapper m-0 p-0 "
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          {size.width < bootStrapGrid.sm ? (
            <MobileNavbar pathParams={props.pathParams} />
          ) : (
            <ExpandedNavbar
              activeLink={props.activeLink}
              pathParams={props.pathParams}
            />
          )}
          {errors &&
            errors.map((error, index) => {
              return (
                <WarningMessage
                  errorTitle={error.errorTitle}
                  errorContent={error.errorContent}
                  clearError={() => clearError(index)}
                  key={index}
                />
              );
            })}
          <main id="maincontent">
            <BasicSearch
              toggleShowSortersAndFilters={toggleShowSortersAndFilters}
              fetchSearchResults={props.fetchSearchResults}
              expand={props.searchResult.data.length === 0}
              setSearchParams={props.setSearchParams}
              searchParams={props.searchParams}
              pathParams={props.pathParams}
            />
            <SearchResults
              aria-busy="true"
              toggleShowSortersAndFilters={toggleShowSortersAndFilters}
              showSortersAndFilters={showSortersAndFilters}
              setFiltersSelected={setFiltersSelected}
              filtersSelected={filtersSelected}
              fetchSearchResults={props.fetchSearchResults}
              searchParams={props.searchParams}
              setSearchParams={props.setSearchParams}
              viewMode={props.viewMode}
              setViewMode={props.setViewMode}
              data={data}
              loading={props.searchResult.loading}
              onChangePage={props.onChangePage}
              currentPage={props.currentPage}
              masonryPage={props.masonryPage}
              sorter={props.sorter}
              onChangeSorter={props.onChangeSorter}
              setShowItems={props.setShowItems}
              pathParams={props.pathParams}
            />
          </main>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default withRouter(SearchResultPage);
