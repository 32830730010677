import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../i18n";
import Container from "react-bootstrap/Container";
import MobileNavbar from "../components/header/MobileNavbar";
import ExpandedNavbar from "../components/header/ExpandedNavbar";
import PatentDetails from "../components/patentDetails/PatentDetails";
import "../scss/styles/app.scss";
import UseWindowSize from "../customHooks/useWindowSize";
import { bootStrapGrid } from "../constants/constants";
import Footer from "../components/common/Footer";

const PatentDetailsPage = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  document.title = translate("patent:details_title") + " | PRH";

  useEffect(() => {
    props.pathParams.updateLanguage();
  }, [props.pathParams]);

  return (
    <>
      <div id="skip">
        <a href="#maincontent">{translate("jumplink_to_content")}</a>
      </div>
      <Container
        fluid="true"
        className=" page-wrapper m-0 p-0 "
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          {size.width < bootStrapGrid.md ? (
            <MobileNavbar pathParams={props.pathParams} />
          ) : (
            <ExpandedNavbar
              activeLink={props.activeLink}
              pathParams={props.pathParams}
            />
          )}
          <main id="maincontent">
            <PatentDetails
              fetchPatent={props.fetchPatent}
              patent={props.patent}
              pathParams={props.pathParams}
            />
          </main>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default withRouter(PatentDetailsPage);
