import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import { withRouter } from "react-router-dom";
import { formatDate, findLanguageVersion } from "../../../helpers/helpers";

const paymentUrl = process.env.REACT_APP_PAYMENT_SERVICE_URL;

const PaymentInfo = (props) => {
  const { translate, langCode } = useContext(I18nContext);
  const lang = props.match.params.lang;

  const data = props.data;

  const applicationType = () => {
    if (data.applicationNumber.startsWith("EP")) return "ep";
    else if (
      data.applicationNumber.startsWith("L") ||
      data.applicationNumber.startsWith("C")
    )
      return "spc";
    else if (data.applicationNumber.startsWith("U")) return "um";
    else return "pa";
  };
  
  const isPenaltyPay = (identifier) => {
    const penaltyPaymentIdentifiers = ['F042', 'F042', 'F042'];
    return penaltyPaymentIdentifiers.includes(identifier) || identifier.includes('S0');
  }
  
  const getPaymentIdentifierTranslation = (identifier) => {
    if(isPenaltyPay(identifier))
      return translate('paymenttype.type.penalty')
    return translate("paymenttype.type." +
        identifier.toLowerCase() +
        "_" +
        applicationType());
  }

  const getDueDate = (element) => {
    if( element.limitDate && isLatePayment(element))
      return formatDate(element.limitDate);
    else if( element.dueDate)
      return formatDate(element.dueDate);
    else
      return "-"
  }

  const isLatePayment = (payment) => {
    const now = new Date();
    now.setHours(0,0,0,0);
    const dueDate = new Date(payment.dueDate);
    dueDate.setHours(0,0,0,0);
    return dueDate < now
  }

  const hasPayments = (paymentDetails) => {
    return paymentDetails?.payable?.length > 0 || paymentDetails?.paid?.length > 0;
  };

  return(
    <>
    <div className="pb-4">
        <h3 className="pt-4">{translate("payment_details_upcoming")}</h3>
        {data.paymentDetails?.payable && data.paymentDetails?.payable?.length > 0 ? (
          <table className="table">
            <thead className="pt-3 pb-2">
              <tr>
                <th>
                  <span>{translate("patent:details_duedate")}</span>
                </th>
                <th>
                  <span>{translate("patent:details_paymenttype")}</span>
                </th>
                <th>
                  <span>{translate("payment_details_sum")}</span>
                </th>
                <th>
                  <span>{translate("payment_details_reference")}</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {data.paymentDetails?.payable.map((payment) => 
                (
                  <tr key={payment.ordinal}>
                    <td>
                      <span style={{ color: "#323232" }}>
                        {getDueDate(payment)}
                      </span>
                    </td>
                    <td>
                      <span style={{ color: "#323232" }}>
                        { payment.identifier
                          ? getPaymentIdentifierTranslation(payment.identifier) : "-"}
                      </span>
                    </td>
            
                    <td>
                      <span style={{ color: "#323232" }}>
                        {payment.feePayableAmount
                          ? `${payment.feePayableAmount} €`
                          : "-"}
                      </span>
                    </td>
                    <td>
                      <span style={{ color: "#323232" }}>
                        {payment.creditorReference
                          ? payment.creditorReference
                          : "-"}
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          <Row className="pt-4">
            <Col>
              <span>{translate("payment_details_no_open_payments")}</span>
            </Col>
          </Row>
        )}
      </div>
    <div className="pb-4">
      <h3 className="pt-4">{translate("payment_details_paid")}</h3>
      {data.paymentDetails?.paid && data.paymentDetails.paid?.length > 0 ? (
        <table className="table">
          <thead className="pt-3 pb-2">
            <tr>
              <th>
                <span>{translate("patent:details_paymentdate")}</span>
              </th>
              <th>
                <span>{translate("patent:details_paymenttype")}</span>
              </th>
              <th>
                <span>{translate("payment_details_sum")}</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {data.paymentDetails.paid.map((payment) => (
              <tr key={payment.ordinal}>
                <td>
                  <span style={{ color: "#323232" }}>
                    {formatDate(payment.paymentDate)}
                  </span>
                </td>
                <td>
                  <span style={{ color: "#323232" }}>
                    {payment.identifier
                      ? getPaymentIdentifierTranslation(payment.identifier) : "-"}
                  </span>
                </td>

                <td>
                  <span style={{ color: "#323232" }}>
                    {payment.feePaidAmount
                      ? `${payment.feePaidAmount} €`
                      : "-"}
                  </span>
                </td>
              </tr>
            )
            )}
          </tbody>
        </table>
      ) : (
        <Row className="pt-4">
          <Col>
            <span>{translate("payment_details_no_paid_payments")}</span>
          </Col>
        </Row>
      )}
    </div>
    {hasPayments(data.paymentDetails) ?
    <div className="pb-4">
      
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={paymentUrl +
            "/payDirect/" +
            data.applicationNumber +
            "/" +
            langCode}
        ><button className="btn btn-secondary mb-4">
          {translate("patent:details_goto_patent_payment")}
          </button>
        </a>
     
    </div>
    :
    ""
    }
  </>
  )
};

export default withRouter(PaymentInfo);