import React, { useContext, useState, useEffect } from "react";
import { Form, Button, Col, Row, Container } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import { withRouter } from "react-router-dom";
import {
  allPatentTypes,
  allPatentStatuses,
  registeredPatentStatuses,
} from "../../constants/constants";
import { initialSearchParams } from "../../constants/constants";
import { validateSearchForm } from "./formValidators";
import ToolTip from "../common/ToolTip";
import DatePick from "../common/DatePicker";
import { format, set } from "date-fns";

const SearchForm = (props) => {
  const { translate } = useContext(I18nContext);
  const [validationErrors, setValidationErrors] = useState([]);
  const [button, setButton] = useState(false);
  const fields = props.searchParams;
  document.title = translate("patent_advanced_search_title") + " | PRH";
  const [radioValue, setRadioValue] = useState("");

  useEffect(() => {
    if (
      fields.dossierStatus.length < 1 ||
      fields.dossierStatus === allPatentStatuses ||
      fields.dossierStatus === "all"
    ) {
      setRadioValue("all");
    } else {
      setRadioValue("registered");
    }
  }, [fields]);

  const allTypesSelected = allPatentTypes.every((item) =>
    props?.searchParams?.patentTypes?.includes(item)
  );

  const [chosenTypes, setChosenTypes] = useState(
    props.searchParams.patentTypes.length === allPatentTypes.length
      ? []
      : props.searchParams.patentTypes
  );

  const [spcSelected, setSpcSelected] = useState(chosenTypes.includes("Spc"));

  const ref = React.createRef();
  const handleReset = () => {
    setChosenTypes([]);
    props.setSearchParams(initialSearchParams);
    setValidationErrors([]);
  };

  const handleChange = (event) => {
    const newSearchParams = {
      ...props.searchParams,
      [event.target.name]: event.target.value,
    };
    props.setSearchParams(newSearchParams);
  };

  const handleTypeChange = (event) => {
    const clickedType = event.target.name;
    let newTypes = [...chosenTypes];

    
    
    if (chosenTypes.includes(clickedType)) {
      newTypes = chosenTypes.filter(
        (item) => item.toLowerCase() !== clickedType.toLowerCase()
      );
      if (clickedType === "Spc") {
        setSpcSelected(false);
      }
    } else if (clickedType === "Spc") {
      newTypes = ["Spc"];
      setSpcSelected(true);
    } else {
      newTypes.push(clickedType);
      newTypes = newTypes.filter((item) => item !== "Spc");
      setSpcSelected(false);
    }
    setChosenTypes(newTypes);
    if (newTypes.length < 1) {
      newTypes = allPatentTypes;
    }

    props.setSearchParams({ ...props.searchParams, patentTypes: newTypes });

    return setButton(!button);
  };

  const handleDateChange = (id, date) => {
    let isoDate = date ? format(date, "yyyy-MM-dd") : "";
    const newSearchParams = {
      ...props.searchParams,
      [id]: isoDate,
    };
    props.setSearchParams(newSearchParams);
  };

  const handleFocusOut = () => {
    setValidationErrors(validateSearchForm(fields));
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      setValidationErrors(validateSearchForm(fields));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validationErrors.length > 0) {
      document.getElementById(validationErrors[0]).focus();
    } else {
      const dossierStatuses =
        fields.dossierStatus === "registered"
          ? registeredPatentStatuses
          : allPatentStatuses;

      const searchParams = fields;
      searchParams.basicSearch = "";
      searchParams.patentTypes =
        chosenTypes.length > 0 ? chosenTypes : allPatentTypes;
      searchParams.dossierStatus = dossierStatuses;
      props.fetchSearchResults(searchParams);

      props.history.push({ pathname: props.pathParams.buildPath("results") });
    }
  };

  const validPatentTitle = !validationErrors.includes("patentTitle");
  const validApplicationNumber =
    !validationErrors.includes("applicationNumber");
  const validRegistrationNumber =
    !validationErrors.includes("registrationNumber");
  const validPriorityNumber = !validationErrors.includes("priorityNumber");
  const validApplicant = !validationErrors.includes("applicant");
  const validAssignee = !validationErrors.includes("assignee");
  const validInventor = !validationErrors.includes("inventor");
  const validRepresentative = !validationErrors.includes("representative");
  const validCpcClassification =
    !validationErrors.includes("cpcClassification");
  const validIpcClassification =
    !validationErrors.includes("ipcClassification");
  const validApplicationDate = !validationErrors.includes("applicationDate");
  const validFilingDate = !validationErrors.includes("filingDate");
  const validOppositionPeriodStartDate = !validationErrors.includes(
    "oppositonPeriodStartDate"
  );
  const validGrantDate = !validationErrors.includes("grantDate");
  const validPriorityDate = !validationErrors.includes("priorityDate");
  const validPublicationDate = !validationErrors.includes("publicationDate");
  const validNoAuthoAppm = !validationErrors.includes("noAuthoAppm");
  const validBasePatentNumber = !validationErrors.includes("spcBasePatentNumber");

  return (
    <div style={{ position: "relative" }}>
      <Row className="searchfield-background mr-0">
        <Col className="pr-0">
          <Container>
            <h1 className="pt-4">
              {translate("patent_advanced_search_title")}
            </h1>
          </Container>
        </Col>
      </Row>
      <Container style={{ position: "inherit", top: "-90px" }}>
        <div className="prh-form pb-0">
          <div className="prh-form__container p-0">
            <section
              className="prh-section-borders"
              style={{ borderTop: "none" }}
            >
              <Form onSubmit={handleSubmit} ref={ref}>
                <fieldset>
                  <legend className="sr-only">
                    {translate("advanced_search_select_patent_types")}
                  </legend>

                  <Row>
                    <Col xs={12} md={6}>
                      <Button
                        aria-pressed={
                          chosenTypes.includes("PatentDossier") ? true : false
                        }
                        style={{ width: "100%" }}
                        className={
                          chosenTypes.includes("PatentDossier")
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                        name="PatentDossier"
                        onClick={handleTypeChange}
                      >
                        {translate("patent_type_patentdossier")}
                      </Button>
                    </Col>
                    <Col xs={12} md={6}>
                      <Button
                        aria-pressed={
                          chosenTypes.includes("PatentDossierUtilityModel")
                            ? true
                            : false
                        }
                        style={{ width: "100%" }}
                        className={
                          chosenTypes.includes("PatentDossierUtilityModel")
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                        name="PatentDossierUtilityModel"
                        onClick={handleTypeChange}
                      >
                        {translate("patent_type_patentdossierutilitymodel")}
                      </Button>
                    </Col>
                  </Row>
                  <Row className="pb-4">
                    <Col xs={12} md={6}>
                      <Button
                        aria-pressed={
                          chosenTypes.includes("PatentEurope") ? true : false
                        }
                        style={{ width: "100%" }}
                        className={
                          chosenTypes.includes("PatentEurope")
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                        name="PatentEurope"
                        onClick={handleTypeChange}
                      >
                        {translate("patent_type_patenteurope")}
                      </Button>
                    </Col>
                    <Col xs={12} md={6}>
                      <Button
                        aria-pressed={
                          chosenTypes.includes("Spc") ? true : false
                        }
                        style={{ width: "100%" }}
                        className={
                          chosenTypes.includes("Spc")
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                        name="Spc"
                        onClick={handleTypeChange}
                      >
                        {translate("patent_type_spc")}
                      </Button>
                    </Col>
                  </Row>
                </fieldset>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Row className="align-items-center pl-3">
                        <Form.Label className="mb-0" htmlFor="patentTitle">
                          {translate(
                            !spcSelected
                              ? "patent_searchform_patent_title"
                              : "patent_searform_patent_title_spc"
                          )}
                        </Form.Label>
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_patent_title"
                          )}
                          id="patentTitleTooltip"
                        />
                      </Row>
                      <Form.Control
                        className={
                          validPatentTitle ? "border border-dark" : "is-invalid"
                        }
                        aria-describedby="patentTitleTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="patentTitle"
                        name="patentTitle"
                        value={fields.patentTitle}
                        data-cy="patentTitle"
                      />
                      {validPatentTitle ? null : (
                        <div
                          className="invalid-feedback"
                          role="alert"
                          data-cy="patentTitleError"
                        >
                          {translate("patent_title_warning")}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Row className="align-items-center pl-3">
                        <Form.Label
                          className="mb-0"
                          htmlFor="applicationNumber"
                        >
                          {translate("patent_searchform_applicationnumber")}
                        </Form.Label>
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_applicationnumber"
                          )}
                          id="applicationNumberTooltip"
                        />
                      </Row>
                      <Form.Control
                        className={
                          validApplicationNumber
                            ? "border border-dark"
                            : "is-invalid"
                        }
                        aria-describedby="applicationNumberTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="applicationNumber"
                        name="applicationNumber"
                        value={fields.applicationNumber}
                        data-cy="applicationNumber"
                      />
                      {validApplicationNumber ? null : (
                        <div
                          className="invalid-feedback"
                          role="alert"
                          data-cy="applicationNumberError"
                        >
                          {translate("patent_applicationnumber_warning")}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Row className="align-items-center pl-3">
                        <Form.Label
                          className="mb-0"
                          htmlFor="registrationNumber"
                        >
                          {translate(
                            !spcSelected
                              ? "patent_searchform_registrationnumber"
                              : "patent_searchform_registrationnumber_spc"
                          )}
                        </Form.Label>
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_registrationnumber"
                          )}
                          id="registrationNumberTooltip"
                        />
                      </Row>
                      <Form.Control
                        className={
                          validRegistrationNumber
                            ? "border border-dark"
                            : "is-invalid"
                        }
                        aria-describedby="registrationNumberTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="registrationNumber"
                        name="registrationNumber"
                        value={fields.registrationNumber}
                        data-cy="registrationNumber"
                      />
                      {validRegistrationNumber ? null : (
                        <div
                          className="invalid-feedback"
                          role="alert"
                          data-cy="registrationNumberError"
                        >
                          {translate("patent_registrationnumber_warning")}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  {!spcSelected && (
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Row className="align-items-center pl-3">
                          <Form.Label className="mb-0" htmlFor="priorityNumber">
                            {translate("patent_searchform_prioritynumber")}
                          </Form.Label>
                          <ToolTip
                            text={translate(
                              "patent_infotext_searchform_prioritynumber"
                            )}
                            id="priorityNumberTooltip"
                          />
                        </Row>
                        <Form.Control
                          className={
                            validPriorityNumber
                              ? "border border-dark"
                              : "is-invalid"
                          }
                          aria-describedby="priorityNumberTooltip"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleFocusOut}
                          onKeyDown={(event) => keyPressHandler(event)}
                          id="priorityNumber"
                          name="priorityNumber"
                          value={fields.priorityNumber}
                          data-cy="priorityNumber"
                        />
                        {validPriorityNumber ? null : (
                          <div
                            className="invalid-feedback"
                            role="alert"
                            data-cy="priorityNumberError"
                          >
                            {translate("patent_prioritynumber_warning")}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Row className="align-items-center pl-3">
                        <Form.Label
                          className="mb-0"
                          htmlFor="spcBasePatentNumber"
                        >
                          {translate("patent_searchform_spcBasePatentNumber")}
                        </Form.Label>
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_spcBasePatentNumber"
                          )}
                          id="spcBasePatentNumberTooltip"
                        />
                      </Row>
                      <Form.Control
                        className={
                          validBasePatentNumber ? "border border-dark" : "is-invalid"
                        }
                        aria-describedby="spcBasePatentNumber"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="spcBasePatentNumber"
                        name="spcBasePatentNumber"
                        value={fields.spcBasePatentNumber}
                        data-cy="spcBasePatentNumber"
                      />
                      {validBasePatentNumber ? null : (
                        <div
                          className="invalid-feedback"
                          role="alert"
                          data-cy="spcBasePatentNumberError"
                        >
                          {translate("patent_spcBasePatentNumber_warning")}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Row className="align-items-center pl-3">
                        <Form.Label className="mb-0" htmlFor="noAuthoAppm">
                          {translate("patent_searchform_noauthoappm")}
                        </Form.Label>
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_noauthoappm"
                          )}
                          id="noAuthoAppmTooltip"
                        />
                      </Row>
                      <Form.Control
                        className={
                          validNoAuthoAppm ? "border border-dark" : "is-invalid"
                        }
                        aria-describedby="noAuthoAppmTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="noAuthoAppm"
                        name="noAuthoAppm"
                        value={fields.noAuthoAppm}
                        data-cy="noAuthoAppm"
                      />
                      {validNoAuthoAppm ? null : (
                        <div
                          className="invalid-feedback"
                          role="alert"
                          data-cy="noAuthoAppmError"
                        >
                          {translate("patent_noauthoappm_warning")}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Row>
                    <Col md={spcSelected ? 6 : 12}>
                      <fieldset aria-describedby="dossierStatusTooltip">
                        <legend
                          style={{
                            fontSize: "1rem",
                            width: "auto",
                            marginBottom: "0rem",
                          }}
                        >
                          <Col xs={12}>
                            <Row className="align-items-center">
                              {translate(
                                !spcSelected
                                  ? "patent_searchform_current_state"
                                  : "patent_searchform_current_state_spc"
                              )}
                              <ToolTip
                                text={translate(
                                  "patent_infotext_searchform_dossierStatus"
                                )}
                                id="dossierStatusTooltip"
                                role="tooltip"
                              />
                            </Row>
                          </Col>
                        </legend>
                        <Form.Check
                          type="radio"
                          name="dossierStatus"
                          id="dossierStatusRegistered"
                          value="registered"
                          label={translate(
                            "patent.application.preselect.status.active"
                          )}
                          onChange={handleChange}
                          checked={radioValue === "registered"}
                        />

                        <Form.Check
                          type="radio"
                          name="dossierStatus"
                          id="dossierStatusAll"
                          value="all"
                          label={translate(
                            "patent.application.preselect.status.all"
                          )}
                          onChange={handleChange}
                          checked={radioValue === "all"}
                        />
                      </fieldset>
                    </Col>
                    {/* HIDE FOR NOW
                    spcSelected && (
                      <Col md={spcSelected ? 6 : 12}>
                        <fieldset
                        // aria-describedby="dossierStatusTooltipSPC"
                        >
                          <legend
                            style={{
                              fontSize: "1rem",
                              width: "auto",
                              marginBottom: "0rem",
                            }}
                          >
                            <Col xs={12}>
                              <Row className="align-items-center">
                                {translate("patent_searchform_spc_attachment")}
                                { <ToolTip
                                text={translate(
                                  "patent_infotext_searchform_dossierStatus_spc"
                                )}
                                id="dossierStatusTooltipSPC"
                                role="tooltip"
                              /> }
                              </Row>
                            </Col>
                          </legend>
                          <Form.Check
                            type="radio"
                            name="dossierStatus_spc"
                            id="dossierStatusSpcContinue"
                            value="spc_continue"
                            label={translate(
                              "patent.application.preselect.status.spc_continue"
                            )}
                            onChange={handleChange}
                            checked={radioValue === "spc_continue"}
                          />

                          <Form.Check
                            type="radio"
                            name="dossierStatus_spc"
                            id="dossierStatusSpcAnnouncement"
                            value="spc_announcement"
                            label={translate(
                              "patent.application.preselect.status.spc_announcement"
                            )}
                            onChange={handleChange}
                            checked={radioValue === "spc_announcement"}
                          />
                        </fieldset>
                      </Col>
                    )*/}
                  </Row>
                </Form.Group>

                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Row className="align-items-center pl-3">
                        <Form.Label className="mb-0" htmlFor="applicant">
                          {translate("patent_searchform_applicant")}
                        </Form.Label>
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_applicant"
                          )}
                          id="applicantTooltip"
                          alt="tooltiptest"
                          role="tooltip"
                        />
                      </Row>
                      <Form.Control
                        className={
                          validApplicant ? "border border-dark" : "is-invalid"
                        }
                        aria-describedby="applicantTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="applicant"
                        name="applicant"
                        value={fields.applicant}
                        data-cy="applicant"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Row className="align-items-center pl-3">
                        <Form.Label className="mb-0" htmlFor="assignee">
                          {translate("patent_searchform_assignee")}
                        </Form.Label>
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_assignee"
                          )}
                          id="assigneeTooltip"
                          role="tooltip"
                        />
                      </Row>
                      <Form.Control
                        className={
                          validAssignee ? "border border-dark" : "is-invalid"
                        }
                        aria-describedby="assigneeTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="assignee"
                        name="assignee"
                        value={fields.assignee}
                        data-cy="assignee"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Row className="align-items-center pl-3">
                        <Form.Label className="mb-0" htmlFor="inventor">
                          {translate("patent_searchform_inventor")}
                        </Form.Label>
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_inventor"
                          )}
                          id="inventorTooltip"
                          role="tooltip"
                        />
                      </Row>
                      <Form.Control
                        className={
                          validInventor ? "border border-dark" : "is-invalid"
                        }
                        aria-describedby="inventorTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="inventor"
                        name="inventor"
                        value={fields.inventor}
                        data-cy="inventor"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Row className="align-items-center pl-3">
                        <Form.Label className="mb-0" htmlFor="representative">
                          {translate("patent_searchform_representative")}
                        </Form.Label>
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_representative"
                          )}
                          id="representativeTooltip"
                          role="tooltip"
                        />
                      </Row>
                      <Form.Control
                        className={
                          validRepresentative
                            ? "border border-dark"
                            : "is-invalid"
                        }
                        aria-describedby="representativeTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="representative"
                        name="representative"
                        value={fields.representative}
                        data-cy="representative"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <fieldset style={{ width: "100%" }}>
                  <legend
                    style={{
                      fontSize: "1rem",
                      width: "auto",
                      marginBottom: "0rem",
                    }}
                  >
                    <Col xs={12}>
                      <Row className="align-items-center">
                        {translate("patent_searchform_applicationdate")}
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_applicationdate"
                          )}
                          id="applicationDateTooltip"
                          role="tooltip"
                        />
                      </Row>
                    </Col>
                  </legend>

                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="applicationStartDate"
                        >
                          {translate("search_startdate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="applicationDateTooltip"
                          id="applicationStartDate"
                          name="applicationStartDate"
                          valid={validApplicationDate}
                          value={
                            fields.applicationStartDate
                              ? new Date(fields.applicationStartDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        ></DatePick>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="applicationEndDate"
                        >
                          {translate("search_enddate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="applicationDateTooltip"
                          id="applicationEndDate"
                          name="applicationEndDate"
                          valid={validApplicationDate}
                          value={
                            fields.applicationEndDate
                              ? new Date(fields.applicationEndDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{ width: "100%" }}>
                  <legend
                    style={{
                      fontSize: "1rem",
                      width: "auto",
                      marginBottom: "0rem",
                    }}
                  >
                    <Col xs={12}>
                      <Row className="align-items-center">
                        {translate("patent_searchform_filingdate")}
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_filingdate"
                          )}
                          id="filingDateTooltip"
                          role="tooltip"
                        />
                      </Row>
                    </Col>
                  </legend>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="filingStartDate"
                        >
                          {translate("search_startdate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="filingDateTooltip"
                          id="filingStartDate"
                          name="filingStartDate"
                          valid={validFilingDate}
                          value={
                            fields.filingStartDate
                              ? new Date(fields.filingStartDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="filingEndDate"
                        >
                          {translate("search_enddate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="filingDateTooltip"
                          id="filingEndDate"
                          name="filingEndDate"
                          valid={validFilingDate}
                          value={
                            fields.filingEndDate
                              ? new Date(fields.filingEndDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{ width: "100%" }}>
                  <legend
                    style={{
                      fontSize: "1rem",
                      width: "auto",
                      marginBottom: "0rem",
                    }}
                  >
                    <Col xs={12}>
                      <Row className="align-items-center">
                        {translate("patent_searchform_publicationdate")}
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_publicationdate"
                          )}
                          id="publicationDateTooltip"
                          role="tooltip"
                        />
                      </Row>
                    </Col>
                  </legend>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="publicationStartDate"
                        >
                          {translate("search_startdate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="publicationDateTooltip"
                          id="publicationStartDate"
                          name="publicationStartDate"
                          valid={validPublicationDate}
                          value={
                            fields.publicationStartDate
                              ? new Date(fields.publicationStartDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="publicationEndDate"
                        >
                          {translate("search_enddate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="publicationDateTooltip"
                          id="publicationEndDate"
                          name="publicationEndDate"
                          valid={validPublicationDate}
                          value={
                            fields.publicationEndDate
                              ? new Date(fields.publicationEndDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{ width: "100%" }}>
                  <legend
                    style={{
                      fontSize: "1rem",
                      width: "auto",
                      marginBottom: "0rem",
                    }}
                  >
                    <Col xs={12}>
                      <Row className="align-items-center">
                        {translate("patent_searchform_grantdate")}
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_grantdate"
                          )}
                          id="grantDateTooltip"
                          role="tooltip"
                        />
                      </Row>
                    </Col>
                  </legend>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="grantStartDate"
                        >
                          {translate("search_startdate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="grantDateTooltip"
                          id="grantStartDate"
                          name="grantStartDate"
                          valid={validGrantDate}
                          value={
                            fields.grantStartDate
                              ? new Date(fields.grantStartDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="grantEndDate"
                        >
                          {translate("search_enddate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="grantDateTooltip"
                          id="grantEndDate"
                          name="grantEndDate"
                          valid={validGrantDate}
                          value={
                            fields.grantEndDate
                              ? new Date(fields.grantEndDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{ width: "100%" }}>
                  <legend
                    style={{
                      fontSize: "1rem",
                      width: "auto",
                      marginBottom: "0rem",
                    }}
                  >
                    <Col xs={12}>
                      <Row className="align-items-center">
                        {translate("patent_searchform_prioritydate")}
                        <ToolTip
                          text={translate(
                            "patent_infotext_searchform_prioritydate"
                          )}
                          id="priorityDateTooltip"
                          role="tooltip"
                        />
                      </Row>
                    </Col>
                  </legend>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="priorityStartDate"
                        >
                          {translate("search_startdate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="priorityDateTooltip"
                          id="priorityStartDate"
                          name="priorityStartDate"
                          valid={validPriorityDate}
                          value={
                            fields.priorityStartDate
                              ? new Date(fields.priorityStartDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label
                          style={{ fontSize: "13.6px" }}
                          htmlFor="priorityEndDate"
                        >
                          {translate("search_enddate")}
                        </Form.Label>
                        <DatePick
                          aria-describedby="priorityDateTooltip"
                          id="priorityEndDate"
                          name="priorityEndDate"
                          valid={validPriorityDate}
                          value={
                            fields.priorityEndDate
                              ? new Date(fields.priorityEndDate)
                              : ""
                          }
                          handleChange={handleDateChange}
                          handleFocus={handleFocusOut}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </fieldset>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="mb-0" htmlFor="ipcClassification">
                        <Row className="pl-3">
                          {translate("patent_searchform_ipc_class")}
                        </Row>
                        <Row className="align-items-center pl-3">
                          {translate("patent_searchform_classnumber")}

                          {
                            <ToolTip
                              text={translate(
                                "patent_infotext_searchform_ipc_classification"
                              )}
                              aria-label={translate(
                                "patent_infotext_searchform_ipc_classification"
                              )}
                              id="ipcClassificationTooltip"
                              role="tooltip"
                            />
                          }
                        </Row>
                      </Form.Label>
                      <Form.Control
                        className={
                          validIpcClassification
                            ? "border border-dark"
                            : "is-invalid"
                        }
                        aria-describedby="ipcClassificationTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="ipcClassification"
                        name="ipcClassification"
                        value={fields.ipcClassification}
                        data-cy="ipcClassification"
                      />
                      {validIpcClassification ? null : (
                        <div
                          className="invalid-feedback"
                          role="alert"
                          data-cy="ipcClassificationError"
                        >
                          {translate("patent_classification_warning")}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="mb-0" htmlFor="cpcClassification">
                        <Row className="pl-3">
                          {translate("patent_searchform_cpc_class")}
                        </Row>
                        <Row className="align-items-center pl-3">
                          {translate("patent_searchform_classnumber")}

                          {
                            <ToolTip
                              text={translate(
                                "patent_infotext_searchform_cpc_classification"
                              )}
                              id="cpcClassificationTooltip"
                              title="tooltip"
                            />
                          }
                        </Row>
                      </Form.Label>
                      <Form.Control
                        className={
                          validCpcClassification
                            ? "border border-dark"
                            : "is-invalid"
                        }
                        aria-describedby="cpcClassificationTooltip"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        onKeyDown={(event) => keyPressHandler(event)}
                        id="cpcClassification"
                        name="cpcClassification"
                        value={fields.cpcClassification}
                        data-cy="cpcClassification"
                      />
                      {validCpcClassification ? null : (
                        <div
                          className="invalid-feedback"
                          role="alert"
                          data-cy="cpcClassificationError"
                        >
                          {translate("patent_classification_warning")}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pb-4">
                  <Col xs={6}>
                    <Button block type="submit" data-cy="submit">
                      {translate("patent.application.search.button")}
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      block
                      className="btn btn-secondary"
                      onClick={handleReset}
                      data-cy="reset"
                    >
                      {translate("patent.application.clear.button")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </section>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(SearchForm);
