export const formatDate = (isoDate) => {
  if (!isoDate) {
    return null;
  }
  const date = new Date(isoDate);

  let day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  const year = date.getFullYear();
  return day + "." + month + "." + year;
};

export const findLanguageVersion = (items, lang) => {
  if (items.length < 1) {
    return items;
  }
  const itemOfChosenLanguage = items.filter(
    (item) => item.language.toLowerCase() === lang
  );
  if (itemOfChosenLanguage.length > 0) {
    return itemOfChosenLanguage[0];
  } else {
    const itemFI = items.filter((item) => item.language.toLowerCase() === "fi");
    const itemSV = items.filter(
      (abstract) => abstract.language.toLowerCase() === "sv"
    );
    const itemEN = items.filter(
      (abstract) => abstract.language.toLowerCase() === "en"
    );
    return itemFI.length > 0
      ? itemFI[0]
      : itemSV.length > 0
      ? itemSV[0]
      : itemEN[0];
  }
};

export default false;
