import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Col, Row, Container } from "react-bootstrap";
import Icons from "../../scss/prh-icon-sprite.svg";

const Footer = () => {
  const { translate } = useContext(I18nContext);

  return (
    <footer className="prh-site-footer d-print-none">
      <Container>
        <Row className="pt-2">
          <Row className="col-sm-auto justify-content-between align-items-top">
            <Col className="col-sm-3 mt-2">
              <h2 style={{ fontSize: "1.21rem", color: "#ffffff" }}>
                {translate("footer_title")}
              </h2>
              <p>
                {translate("footer_streetaddress_title")}:
                {translate("footer_streetaddress")}
              </p>
              <p>
                {translate("footer_postaddress_title")}:
                {translate("footer_postaddress")}
              </p>
            </Col>
            <Col className="col-sm-auto mt-2">
              <h2 style={{ fontSize: "1.21rem", color: "#ffffff" }}>
                {translate("footer_customer_service")}
              </h2>
              <p>{translate("footer_customer_service_datetime")}</p>
              <p>{translate("footer_customer_service_telephone")}</p>
            </Col>
            <Col className="col-sm-auto mt-2">
              <h2 style={{ fontSize: "1.21rem", color: "#ffffff" }}>
                {translate("footer_info_title")}
              </h2>
              <p>
                <a href={translate("footer_privacy_policy_extlink")}>
                  <span
                    className="menu-text ml-1"
                    style={{ fontSize: "1.1rem", textDecoration: "underline" }}
                  >
                    {translate("footer_privacy_policy_title")}
                  </span>
                  <svg
                    alt={translate("patent_navigation_privacy_policy")}
                    className="prh-icon prh-icon--xs ml-1"
                    style={{ stroke: "#ffffff" }}
                  >
                    <use xlinkHref={`${Icons}#external-link`}></use>
                  </svg>
                </a>
              </p>
              <p>
                <a href={translate("footer_privacy_policy_um_extlink")}>
                  <span
                    className="menu-text ml-1"
                    style={{ fontSize: "1.1rem", textDecoration: "underline" }}
                  >
                    {translate("footer_privacy_policy_um_title")}
                  </span>
                  <svg
                    alt={translate("utilitymodel_navigation_privacy_policy")}
                    className="prh-icon prh-icon--xs ml-1"
                    style={{ stroke: "#ffffff" }}
                  >
                    <use xlinkHref={`${Icons}#external-link`}></use>
                  </svg>
                </a>
              </p>
              <p>
                <a href={translate("footer_accessibility_leaflet_extlink")}>
                  <span
                    className="menu-text ml-1"
                    style={{ fontSize: "1.1rem", textDecoration: "underline" }}
                  >
                    {translate("footer_accessibility_leaflet")}
                  </span>
                  <svg
                    alt={translate("patent_navigation_accessibility_leaflet")}
                    className="prh-icon prh-icon--xs ml-1"
                    style={{ stroke: "#ffffff" }}
                  >
                    <use xlinkHref={`${Icons}#external-link`}></use>
                  </svg>
                </a>
              </p>
            </Col>
          </Row>
        </Row>
        <hr />
        <Row className="align-items-center justify-content-between pb-3">
          <Row className="col-sm-auto align-items-center"></Row>
          <Col className="col-sm-auto align-items-center">
            <small>© {translate("footer_title")}</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
