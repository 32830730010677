import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import UseWindowSize from "../../../customHooks/useWindowSize";
import DataField from "../../common/DataField";
import { formatDate } from "../../../helpers/helpers";
import { bootStrapGrid } from "../../../constants/constants";

const BasicInfo = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();

  const fields = props.data;

  const removeDublicateApplicant = (items) => {
    let applicants = items.applicants.filter((applicant) => {
      let found = false;
      items.owners.forEach((owner) => {
        if (
          (applicant.companyName &&
            owner.companyName &&
            applicant.companyName.indexOf(owner.companyName) >= 0) ||
          (applicant.fullName &&
            owner.fullName &&
            applicant.fullName.indexOf(owner.fullName) >= 0)
        ) {
          found = true;
        }
      });
      return found ? false : true;
    });
    return applicants;
  };

  fields.applicants = removeDublicateApplicant(fields);

  const findPediatricExtensionDate = (items) => {
    if (items.length > 0) {
      const events = items.filter((element) => {
        return (
          element?.event?.toLowerCase() ===
            "supplementary_protection_certificate.pediatric_extension_filed" ||
          element?.events?.toLowerCase ===
            "supplementary_protection_certificate.pediatric_extension_filed"
        );
      });
      if (events.length > 1) {
        return events
          .sort((a, b) => {
            if (a.eventDate === null || b.eventDate === null) return -1;
            return a.eventDate.localeCompare(b.eventDate);
          })
          .reverse();
      } else if (events.length === 1) {
        return events[0].eventDate;
      } else {
        return null;
      }
    }
  };

  fields.pediatric_extension_filed = findPediatricExtensionDate(fields.events);

  const registrationNumber = (
    <div className="pb-2">
      <dt className="m-0 p-0">
        {translate("patent:resultfield_registrationNumber")}
      </dt>
      <dd className="datafield-content pb-0 mb-0">
        {fields.applicationNumber}
      </dd>
      <dd className="datafield-content pb-0 mb-0">
        {fields.registrationNumber}
      </dd>
    </div>
  );
  const arrivalDate = (
    <DataField
      title={translate("patent:resultfield_filingdate")}
      value={formatDate(fields.filingDate) || "-"}
    />
  );
  const status = (
    <div className="pb-2">
      <dt className="m-0 p-0">{translate("patent:resultfield_status")}</dt>
      <dd className="datafield-content pb-0 mb-0">
        {translate("patents_status_" + fields.dossierStatus) || "-"}
      </dd>
      <dd className="datafield-content pb-0 mb-0">
        {formatDate(fields.dossierStatusDate) || "-"}
      </dd>
    </div>
  );
  const applicationType = (
    <div className="pb-2">
      <dt className="m-0 p-0">{translate("application_type")}</dt>
      <dd className="datafield-content pb-0 mb-0">
        {translate("application_type_" + fields.applicationType) || "-"}
      </dd>
      {fields.relatedDossierType && (
        <dd className="datafield-content pb-0 mb-0">
          {translate(
            "dossier_type_" + fields.relatedDossierType.toLowerCase()
          ) || "-"}
        </dd>
      )}
    </div>
  );
  const applicationDate = (
    <DataField
      title={translate("patent:resultfield_applicationdate")}
      value={formatDate(fields.applicationDate) || "-"}
    />
  );

  const spcExpiryDate = (
    <DataField
      title={translate("patent:resultfield_spcexpirydate")}
      value={formatDate(fields.spcExpiryDate) || "-"}
    />
  );
  const priorityInfo = (
    <div className="mb-4">
      <Row>
        <Col xs={12}>
          <span>{translate("patent:details:priorityinfo")}</span>
        </Col>
      </Row>
      {fields.priorityClaims && fields.priorityClaims.length > 0 ? (
        fields.priorityClaims.map((element) => (
          <Row key={element.ordinal}>
            <Col xs={2}>
              <span style={{ color: "#323232" }}>
                {element.officeCode || "-"}
              </span>
            </Col>
            <Col xs={5}>
              <span style={{ color: "#323232" }}>
                {formatDate(element.filingDate) || "-"}
              </span>
            </Col>
            <Col xs={5}>
              <span style={{ color: "#323232" }}>
                {element.applicationNumber || "-"}
              </span>
            </Col>
          </Row>
        ))
      ) : (
        <span style={{ color: "#323232" }}>-</span>
      )}
    </div>
  );

  const patentNumber = (
    <DataField
      title={translate("patent:details_patentnumber")}
      value={fields.basePatentNumber || "-"}
    />
  );

  const spcAuthNumberEU = (
    <>
      {fields.spcAuthorizations?.length > 0 &&
        fields.spcAuthorizations.map((element) =>
          element.idCountry !== "FI" ? (
            <DataField
              key={element.authNo}
              title={translate("patent:details_spcauthnumber_eu")}
              value={
                element.authNo
                  ? element.authNo +
                    " " +
                    (element.date && formatDate(element.date))
                  : "-"
              }
            />
          ) : (
            ""
          )
        )}
    </>
  );

  const spcAuthNumberFI = (
    <>
      {fields.spcAuthorizations?.length > 0 &&
        fields.spcAuthorizations.map((element) =>
          element.idCountry === "FI" ? (
            <DataField
              title={translate("patent:details_spcauthnumber_fi")}
              value={
                element.authNo
                  ? element.authNo +
                    " " +
                    (element.date && formatDate(element.date))
                  : "-"
              }
            />
          ) : (
            ""
          )
        )}
    </>
  );

  const agreementDate = (
    <DataField
      title={translate("patent:details_agreementdate")}
      value={formatDate(fields.grantDate) || "-"}
    />
  );

  const applicant = (
    <div>
      {(fields.applicants && fields.applicants.length > 0) ||
      (fields.owners && fields.owners.length > 0) ? (
        <div className="pb-2">
          <dt className="m-0 p-0">{translate("patent:details_applicant")}</dt>
          {fields.owners.map((element) =>
            element.companyName ? (
              <dd className="datafield-content pb-0 mb-0" key={element.ordinal}>
                {element.companyName}
              </dd>
            ) : (
              <dd className="datafield-content pb-0 mb-0" key={element.ordinal}>
                {(element.lastName ? element.lastName : "") +
                  " " +
                  (element.firstName ? element.firstName : "")}
              </dd>
            )
          )}
          {fields.owners && fields.owners.length > 0
            ? ""
            : fields.applicants.map((element) =>
                element.companyName ? (
                  <dd
                    className="datafield-content pb-0 mb-0"
                    key={element.ordinal}
                  >
                    {element.companyName}
                  </dd>
                ) : (
                  <dd
                    className="datafield-content pb-0 mb-0"
                    key={element.ordinal}
                  >
                    {(element.lastName ? element.lastName : "") +
                      " " +
                      (element.firstName ? element.firstName : "")}
                  </dd>
                )
              )}
        </div>
      ) : (
        <div className="pb-2">
          <dt className="m-0 p-0">{translate("patent:details_applicant")}</dt>
          <dd className="datafield-content pb-0 mb-0">-</dd>
        </div>
      )}
    </div>
  );

  const publicationDate = (
    <DataField
      title={translate("patent:details_publicationdate")}
      value={formatDate(fields.publicationDate) || "-"}
    />
  );

  const pediatric_extension = (
    <DataField
      title={translate("patent:details_pediatric_extension_filed")}
      value={formatDate(fields.pediatric_extension_filed) || "-"}
    />
  );

  const inventors = (
    <div>
      {fields.inventors && fields.inventors.length > 0 ? (
        <div className="pb-2">
          <dt className="m-0 p-0">{translate("patent:details_inventors")}</dt>
          {fields.inventors.map((element) => (
            <dd className="datafield-content pb-0 mb-0" key={element.ordinal}>
              {(element.lastName ? element.lastName : "") +
                " " +
                (element.firstName ? element.firstName : "")}
            </dd>
          ))}
        </div>
      ) : (
        <div className="pb-2">
          <dt className="m-0 p-0">{translate("patent:details_inventors")}</dt>
          <dd className="datafield-content pb-0 mb-0">-</dd>
        </div>
      )}
    </div>
  );

  const researcher = (
    <div>
      {fields.examiner ? (
        <div className="pb-2">
          <dt className="m-0 p-0">{translate("patent:details_researcher")}</dt>
          <dd className="datafield-content pb-0 mb-0">
            {fields.examiner.fullName}
          </dd>
        </div>
      ) : (
        <dl className="pb-2">
          <dt className="m-0 p-0">{translate("patent:details_researcher")}</dt>
          <dd className="datafield-content pb-0 mb-0">-</dd>
        </dl>
      )}
    </div>
  );

  const t3PublicationDate = (
    <DataField
      title={translate("patent:details_t3_publicationdate")}
      value={formatDate(fields.t3PublicationDate) || "-"}
    />
  );

  const representative = (
    <div>
      {fields.representatives && fields.representatives.length > 0 ? (
        <div className="pb-2">
          <dt className="m-0 p-0">
            {translate("patent.application.representative")}
          </dt>
          {fields.representatives.map((element) =>
            element.companyName ? (
              <dd className="datafield-content pb-0 mb-0" key={element.ordinal}>
                {element.companyName}
              </dd>
            ) : (
              <dd className="datafield-content pb-0 mb-0" key={element.ordinal}>
                {(element.lastName ? element.lastName : "") +
                  " " +
                  (element.firstName ? element.firstName : "")}
              </dd>
            )
          )}
        </div>
      ) : (
        <div className="pb-2">
          <dt className="m-0 p-0">
            {translate("patent.application.representative")}
          </dt>
          <dd className="datafield-content pb-0 mb-0">-</dd>
        </div>
      )}
    </div>
  );

  const largeView = (
    <div>
      <Row>
        <Col xs={12} md={4}>
          {registrationNumber}
        </Col>
        <Col xs={12} md={4}>
          {arrivalDate}
        </Col>
        <Col xs={12} md={4}>
          {status}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          {applicationType}
        </Col>
        <Col xs={12} md={4}>
          {applicationDate}
        </Col>
        <Col xs={12} md={4}>
          {fields.applicationType !== "SPC" ? priorityInfo : spcExpiryDate}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          {applicant}
        </Col>
        <Col xs={12} md={4}>
          {agreementDate}
        </Col>
        <Col xs={12} md={4}>
          {patentNumber}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          {representative}
        </Col>
        <Col xs={12} md={4}>
          {publicationDate}
        </Col>
        <Col xs={12} md={4}>
          {spcAuthNumberFI}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          {fields.applicationType !== "SPC" ? inventors : pediatric_extension}
        </Col>
        <Col xs={12} md={4}>
          {fields.applicationNumber.includes("EP") ? t3PublicationDate : researcher}
        </Col>
        <Col xs={12} md={4}>
          {spcAuthNumberEU}
        </Col>
      </Row>
    </div>
  );

  const mobileView = (
    <div>
      <Row>
        <Col xs={12}>{registrationNumber}</Col>
        <Col xs={12}>{applicationType}</Col>
        <Col xs={12}>{patentNumber}</Col>
        <Col xs={12}>{applicant}</Col>
        <Col xs={12}>{representative}</Col>
        <Col xs={12}>{arrivalDate}</Col>
        <Col xs={12}>{applicationDate}</Col>
        <Col xs={12}>{agreementDate}</Col>
        <Col xs={12}>{publicationDate}</Col>
        <Col xs={12}>{status}</Col>
        {fields.applicationType !== "SPC" ? (
          <Col xs={12}>{priorityInfo}</Col>
        ) : (
          <Col xs={12}>{spcExpiryDate}</Col>
        )}
        {fields.applicationType !== "SPC" && <Col xs={12}>{inventors}</Col>}
        {fields.applicationType === "SPC" && (
          <Col xs={12}>{pediatric_extension}</Col>
        )}
        <Col xs={12}>{researcher}</Col>
      </Row>
    </div>
  );

  return <dl>{size.width < bootStrapGrid.sm ? mobileView : largeView}</dl>;
};

export default BasicInfo;
