import EN from "../i18n/messages_patent_en.json";
import FI from "../i18n/messages_patent_fi.json";
import SV from "../i18n/messages_patent_sv.json";
const translations = {
  en: EN,
  fi: FI,
  sv: SV
};

// This function will be used to create `translate` function for the context
const getTranslate = langCode => key => {
  return translations[langCode][key] || key || [];
};

const convertToCSV = (results, lang) => {
  // Add double quotes and handle null values
  const sanitize = (item) => (item ? '"' + item.replace(/"/g, '""') + '"' : "");
  // Pick headers from the first search result
  let header = Object.keys(results[0]);
  
  // Remove oridinal header
  header.pop();
  let csv = results.map((row) => {
    row = flatten(row);
    let result = header
      .map((fieldName) => {
        const keys = Object.keys(row);
        let values = [];
        keys.forEach((key) => {
          // Handle all child items except ordinals
          if (key.includes(fieldName) && !key.includes("ordinal") && row[key] !== null) {
            checkTranslation(row[key], fieldName, lang);
            values.push(checkTranslation(row[key], fieldName, lang));
          }
        });
        if(fieldName === 'titles') {
          let titles = "";
          for (let index = 0; index < values.length; index = index + 2) {
            if(index > 0)
              titles = titles + ', ';
            titles = titles + values[index + 1] + ': ' + values[index];
          }
          return sanitize(titles);
        } 
        return sanitize(values.join(", "));
      })
      .join(";");
    return result;
  });
  // Add headers
  header = header.map((fieldName) => getTranslate(lang)("patent:csv_headers_" + fieldName))
  csv.unshift(header.join(";"));
  csv = csv.join("\r\n");
  return csv;
};

const checkTranslation = (value, fieldName, lang) => {
  switch (fieldName) {
    case 'dossierType':
      return getTranslate(lang)("patent_type_" + value.toLowerCase());
    case 'dossierStatus':
      return getTranslate(lang)("patents_status_" + value);
    default:
      break;
  }
  return value;
};

const flatten = (obj, prefix, current) => {
  prefix = prefix || [];
  current = current || {};

  if (typeof obj === "object" && obj !== null) {
    Object.keys(obj).forEach((key) => {
      flatten(obj[key], prefix.concat(key), current);
    });
  } else {
    current[prefix.join(".")] = obj;
  }

  return current;
};

export const exportCSVFile = (results, fileTitle, lang) => {
  var csv = convertToCSV(results, lang);

  var exportedFilenmae = fileTitle + ".csv" || "results.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export default false;
