import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { findLanguageVersion } from "../../helpers/helpers";
import { Link, withRouter } from "react-router-dom";

const PrhTableCard = (props) => {
  const { translate } = useContext(I18nContext);
  const fields = props.data;
  const lang = document.documentElement.lang;

//  const title =
  //  fields.titles.length > 0 ? findLanguageVersion(fields.titles, lang) : "-";

  const title = () => {
    return fields.dossierType.toLowerCase() === 'spc' ? getSPCTitle() :
    (fields.titles.length > 0 ? findLanguageVersion(fields.titles, lang).title.trim() : "-");
  }

  const getSPCTitle = () => {
    return fields.titles.length <= 0 ? "-" : (findLanguageVersion(fields.titles, lang).title.trim().length > 0 ? 
      findLanguageVersion(fields.titles, lang).title.trim() : findLanguageVersion(fields.titles, 'fi').title.trim());
  }

  const patentCard = (
    <tr
      data-cy="tableRow"
      style={{ cursor: "pointer", overflowWrap: "break-word" }}
    >
      <td style={{}}>
        <Link
          title={title().length > 0 ? title() : "-"}
          aria-label={
            translate("patent_detailed_link") + fields.applicationNumber
          }
          className="btn-link p-0 datafield-content"
          style={{ color: "#035ca7", textDecoration: "underline" }}
          id="applicationNumber"
          to={{
            pathname: props.pathParams.buildPath( "patent/" + fields.applicationNumber )
          }}
        >
          {fields.applicationNumber || "-"}
        </Link>
        <div className="datafield-content">
          {fields.registrationNumber || "-"}
        </div>
      </td>
      <td
        className="datafield-content"
        title={title().length > 0 ? title() : "-"}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          minWidth: !props.showSortersAndFilters ? "300px" : "100px",
          maxWidth: !props.showSortersAndFilters ? "300px" : "100px",
          whiteSpace: !props.showSortersAndFilters ? "normal" : "nowrap",
        }}
      >
        {title().length > 0 ? title() : "-"}
      </td>
      {!props.showSortersAndFilters && (
        <td className="datafield-content" style={{ textAlign: "center" }}>
          {fields.classifications
            ? fields.classifications.length > 0
              ? fields.classifications[0].classification || "-"
              : "-"
            : "-"}
        </td>
      )}
      <td style={{ textAlign: "center" }}>

        {fields.owners && fields.owners.length > 0 ? fields.owners.map((item) => (
          <div className="datafield-content" key={item.ordinal}>
            {item.name || item.companyName || "-"}
          </div>
        )) : fields.applicants.map((item) => (
          <div className="datafield-content" key={item.ordinal}>
            {item.name || item.companyName || "-"}
          </div>
        ))}
      </td>
      <td className="datafield-content" style={{ textAlign: "center" }}>
        {fields.dossierStatus
          ? translate("patents_status_" + fields.dossierStatus)
          : "-"}
      </td>
    </tr>
  );

  return patentCard;
};
export default withRouter(PrhTableCard);
