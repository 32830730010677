import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../i18n";
import { Container, Row, Col } from "react-bootstrap";
import MobileNavbar from "../components/header/MobileNavbar";
import ExpandedNavbar from "../components/header/ExpandedNavbar";
import BasicSearch from "../components/SearchForm/BasicSearch";
import "../scss/styles/app.scss";
import UseWindowSize from "../customHooks/useWindowSize";
import { bootStrapGrid } from "../constants/constants";
import Footer from "../components/common/Footer";
import Icons from "../scss/prh-icon-sprite.svg";

const MainPage = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  document.title = translate("search_patent") + " | PRH";

  useEffect(() => {
    props.pathParams.updateLanguage();
    props.setActiveLink("Home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infotext = (
    <Container>
      <div
        className="pb-3"
        style={{
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        }}
      >
        <Row className="pb-1">
          <Col>
            <h2 className="text-blue mt-2 pt-3">
              {translate("patent_service_infotitle")}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <span htmlFor="search_word" className="mb-0 pb-0">
              {translate("patent_service_infotext")}
            </span>
          </Col>
        </Row>
        <Row className="pb-1">
          <Col>
            <h3 className="text-blue mt-2 pt-3">
              {translate("patent_service_unitary_patent_infotitle")}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <span className="mb-0 pb-0">
              {translate("patent_service_unitary_patent_infotext")}
            </span>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col xs={12}>
            <a href={translate("european_patent_registe_link_url")} rel="noreferrer" target="_blank">
              <span style={{ fontSize: "1.1rem", textDecoration: "underline" }}>
              {translate("european_patent_registe_link_label")}
              </span>
            </a>
            <svg
              alt={translate("patent_navigation_privacy_policy")}
              className="prh-icon prh-icon--xs ml-1"
            >
              <use xlinkHref={`${Icons}#external-link`}></use>
            </svg>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col xs={12}>
            <a href={translate("unitary_patent_link_url")} rel="noreferrer" target="_blank">
              <span style={{ fontSize: "1.1rem", textDecoration: "underline" }}>
              {translate("unitary_patent_link_label")}
              </span>
            </a>
            <svg
              alt={translate("patent_navigation_privacy_policy")}
              className="prh-icon prh-icon--xs ml-1"
            >
              <use xlinkHref={`${Icons}#external-link`}></use>
            </svg>
          </Col>
        </Row> 
      </div>
    </Container>
  );

  return (
    <>
      <div id="skip">
        <a href="#maincontent">{translate("jumplink_to_content")}</a>
      </div>
      <Container
        fluid="true"
        className=" page-wrapper m-0 p-0 "
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          {size.width < bootStrapGrid.sm ? (
            <MobileNavbar pathParams = { props.pathParams }/>
          ) : (
            <ExpandedNavbar activeLink={props.activeLink} pathParams = { props.pathParams }/>
          )}
          <main id="maincontent">
            <BasicSearch
              fetchSearchResults={props.fetchSearchResults}
              expand={true}
              setSearchParams={props.setSearchParams}
              searchParams={props.searchParams}
              pathParams = { props.pathParams }
            />
            {infotext}
          </main>
          </div>
          <Footer />
      </Container>
    </>
  );
};

export default withRouter(MainPage);
