import React, { useContext, useState } from "react";
import { I18nContext } from "../../i18n";
import { formatDate, findLanguageVersion } from "../../helpers/helpers";
import DataField from "../common/DataField";
import { Link, withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Icons from "../../scss/prh-icon-sprite.svg";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";

const PrhCard = (props) => {
  const size = UseWindowSize();
  const { translate } = useContext(I18nContext);
  const initialExpanded = size.width >= bootStrapGrid.sm;
  const [expanded, setExpanded] = useState(initialExpanded);
  const fields = props.data;

  const lang = document.documentElement.lang;

  const handleClick = () => {
    if (size.width < bootStrapGrid.sm) {
      setExpanded(!expanded);
    }
  };

  const title = () => {
    return fields.dossierType.toLowerCase() === 'spc' ? getSPCTitle() :
    (fields.titles.length > 0 ? findLanguageVersion(fields.titles, lang).title.trim() : "-");
  }

  const getSPCTitle = () => {
    return fields.titles.length <= 0 ? "-" : (findLanguageVersion(fields.titles, lang).title.trim().length > 0 ? 
      findLanguageVersion(fields.titles, lang).title.trim() : findLanguageVersion(fields.titles, 'fi').title.trim());
  }

  const patentCard = (
    <div
      onClick={handleClick}
      data-cy="card"
      className="prh-card pt-4 pl-4 pr-4"
    >
      <dl>
        <Row>
          <Col xs={11} md={12}>
            <dl data-cy="titleField" className="pb-2">
              <dt className="m-0 p-0">
                {translate("patent:resultfield_name")}
              </dt>
              <dd
                className="datafield-content"
                role="heading"
                aria-level="3"
                style={{ fontSize: "1.1rem" }}
              >
                {title().length > 0 ? title() : "-"}
              </dd>
            </dl>
          </Col>
          {size.width < bootStrapGrid.sm ? (
            <Col xs={1} md={0} className="p-0">
              {expanded ? (
                <svg
                  alt=""
                  className="prh-icon"
                  style={{ height: "21px", width: "21px" }}
                >
                  <use xlinkHref={`${Icons}#chevron-up`}></use>
                </svg>
              ) : (
                <svg
                  alt=""
                  className="prh-icon"
                  style={{ height: "21px", width: "21px" }}
                >
                  <use xlinkHref={`${Icons}#chevron-down`}></use>
                </svg>
              )}
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <dl className="pb-2">
              <dt className="m-0 p-0">
                {translate("patent:resultfield_registrationNumber")}
              </dt>
              <Link
                title={title().length > 0 ? title() : "-"}
                aria-label={
                  translate("patent_detailed_link") + fields.applicationNumber
                }
                
                className="btn-link p-0"
                to={{
                  pathname: props.pathParams.buildPath( "patent/" + fields.applicationNumber ),
                }}
              >
                <dd
                  className="datafield-content pb-0 mb-0"
                  style={{ color: "#035ca7", textDecoration: "underline" }}
                  id="applicationNumber"
                  data-cy="applicationNumber"
                >
                  {fields.applicationNumber || "-"}
                </dd>
              </Link>
              <dd
                data-cy="registrationNumber"
                className="datafield-content pb-0 mb-0"
              >
                {fields.registrationNumber || "-"}
              </dd>
            </dl>
          </Col>
          <Col sm={12} md={4}>
            <DataField
              title={translate("patent:resultfield_applicationdate")}
              value={formatDate(fields.applicationDate) || "-"}
              data_cy="dateField"
            />
          </Col>
          {expanded ? (
            <Col sm={12} md={4}>
              <DataField
                title={translate("patent:resultfield_status")}
                data_cy="dossierStatus"
                value={
                  fields.dossierStatus
                    ? translate("patents_status_" + fields.dossierStatus)
                    : "-"
                }
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
        {expanded ? (
          <Row>
            <Col sm={12} md={4}>
              <dl data-cy="applicant" className="pb-2">
                <dt className="m-0 p-0">
                  {translate("patent:resultfield_applicant")}
                </dt>
                {fields.owners && fields.owners.length > 0 ? 
                fields.owners.map((item, index) => (
                  <dd
                    className="datafield-content"
                    name-number-cy={index}
                    key={item.ordinal}
                  >
                    {item.name || item.companyName || "-"}
                  </dd>
                )) : 
                fields.applicants.map((item, index) => (
                  <dd
                    className="datafield-content"
                    name-number-cy={index}
                    key={item.ordinal}
                  >
                    {item.name || item.companyName || "-"}
                  </dd>
                ))}
              </dl>
            </Col>
            <Col sm={12} md={4}>
              <dl className="pb-2">
                <dt className="m-0 p-0">
                  {translate("patent:resultfield_ipc_classes")}
                </dt>
                <dd className="datafield-content">
                  {fields.classifications
                    ? fields.classifications.length > 0
                      ? fields.classifications[0].classification || "-"
                      : "-"
                    : "-"}
                </dd>
              </dl>
            </Col>
            <Col sm={12} md={4}>
              <dl>
                <dd>
                  <DataField
                    title={translate("patent:resultfield_statusdate")}
                    value={formatDate(fields.statusDate) || "-"}
                  />
                </dd>
              </dl>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </dl>
    </div>
  );

  return <div className="pb-3"> {patentCard}</div>;
};
export default withRouter(PrhCard);
