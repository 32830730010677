import React, { useState, useRef, useContext } from "react";
import { Overlay, Tooltip, Button } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import Icons from "../../scss/prh-icon-sprite.svg";
import { bootStrapGrid } from "../../constants/constants";
import UseWindowSize from "../../customHooks/useWindowSize";
import helpCircle from "../../scss/styles/images/help-circle.svg";
import helpCircleBlue from "../../scss/styles/images/help-circle-blue.svg";

const ToolTip = (props) => {
  const size = UseWindowSize();
  const { translate } = useContext(I18nContext);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { text, id } = props;
  const placement = props.placement
    ? props.placement
    : size.width < bootStrapGrid.sm
    ? "bottom"
    : "right";
  const closeTooltip = () => {
    setShow(false);
  };

  const handleClick = () => {
    setShow(!show);
    target.current.focus();
  };

  const keyPressHandler = (event) => {
    if (event.key === "Escape") {
      closeTooltip();
    }
  };

  return (
    <div className="d-flex justify-content-end">
      {props.circleStyle ? (
        <Button
          className="btn-icon p-0 m-0 ml-2 mb-1"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.0)", border: "none" }}
          ref={target}
          onClick={handleClick}
          onKeyUp={keyPressHandler}
          aria-describedby={id}
          data-cy={id}
          aria-label={translate("patent_navigation_toggle_tooltip")}
        >
          <img
            alt="ToolTip"
            name="helpCircle"
            src={props.circleStyle === "white" ? helpCircle : helpCircleBlue}
            height="28"
          />
        </Button>
      ) : (
        <Button
          className="btn-icon p-0 m-0 ml-2 mb-1"
          style={{ backgroundColor: "white", border: "none" }}
          ref={target}
          onClick={handleClick}
          onKeyUp={keyPressHandler}
          aria-describedby={id}
          data-cy={id}
          aria-label={translate("patent_navigation_toggle_tooltip")}
        >
          <svg className="prh-icon" style={{ height: "30px", width: "30px" }}>
            <use xlinkHref={`${Icons}#help-circle`}></use>
          </svg>
        </Button>
      )}
      <Overlay
        target={target.current}
        show={show}
        placement={placement}
        onHide={closeTooltip}
        rootClose={true}
      >
        {(props) => (
          <Tooltip id={id} {...props} className="tooltip" role="tooltip">
            <div
              onClick={closeTooltip}
              style={
                size.width < bootStrapGrid.sm
                  ? { width: "300px" }
                  : { width: "500px" }
              }
              data-cy={id + "Role"}
              role="tooltip"
              dangerouslySetInnerHTML={{ __html: text}}
            >
              
            </div>
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};

export default ToolTip;
